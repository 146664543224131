<template>
  <div class="doctor_base">

    <div class="doctor_title_box">

      <div class="doctor_base_title">
        <div @click='changebasePage(1)' :class="{checked: basePage==1}" class="pointer ">基本</div>
        <div @click='changebasePage(2)' :class="{checked: basePage==2}" class="pointer ">任务库</div>
        <div @click='changebasePage(3)' :class="{checked: basePage==3}" class="pointer ">校对库</div>
      </div>

      <div v-show="basePage==2" class="doctor_title_boxR">
        <a-input-search class="onSearchBox" v-model="keyword" placeholder="请输入搜索内容..." enter-button
          @search="onSearch" />
        <a-button @click="doctorAdd"> 新增 </a-button>
        <a-button @click="showUPCheck"> 专家信息核对 </a-button>
        <a-button @click="showUP" type="primary"> 批量导入 </a-button>
      </div>
      <div v-if="basePage==3" class="doctor_title_boxR">
        <a-select v-if="video_data&&video_data[0]&&video_data[0].id" style="width:480px" size='large'
          @change="getTaskCheckFN" :default-value="video_data[0].id">
          <a-select-option disabled value="">
            请选择
          </a-select-option>
          <a-select-option v-for="(item,index) in video_data" :key="index" :value="item.id">
            {{item.title}}
          </a-select-option>
        </a-select>
        <!-- <div class="doc_name_title">
          2023浦江前列腺癌高峰论坛（2023.12.16）
          <img width="16px" src="@/assets/矩形 2@2x.png" alt="">
        </div> -->
      </div>

    </div>

    <div v-show="basePage==1" class="doctor_base_content">

      <!-- 左侧手机模板——列表页 -->
      <div v-show="page==1" class="doctor_base_contentL">
        <div class="phone_title_home">
          <img width="100%" src="@/assets/phone_title.png" alt="">
          <div class="phone_title_txt text">专家列表</div>
        </div>
        <div ref='changeListStyle' class="DBL_list">

          <div class="DBL_list_inp">
            <a-input ref="userNameInput" placeholder="请输入医生姓名搜索">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>

          <div class="DBL_list_All">

            <div v-show="baseData.is_enable_tab==1&&baseData&&baseData.menu_tab&&baseData.menu_tab[0]"
              class="DBL_list_TAB">
              <div v-for="(item,index) in baseData.menu_tab" :key="index" class="DBL_list_TAB_item">
                <span :class="{ckecked: index==0}" class="DBL_list_TABL_title">{{item.menu_title}}</span>
                <span v-show="index==0" class="DBL_list_TABL_line"></span>
              </div>
            </div>

            <div class="DBL_list_Base_RBig">
              <div :class="{DBL_list_BaseSelect: list=='A'}" v-for="(key,list) in dataList" :key="list">{{list}}</div>
            </div>
            <div v-show="key[0]" v-for="(key,list) in dataList" :key="list" class="DBL_list_Base">
              <div class="DBL_list_BaseBig">{{list}}</div>

              <div v-for="speaker in key" :key="speaker.id" class="DBL_list_item">
                <div class="DBL_list_itemL">
                  <img width="100%" height="100%" src="@/assets/noImg.webp" alt="">
                </div>
                <div class="DBL_list_itemR">
                  <div class="DBL_list_itemRT">{{speaker.name}}</div>
                  <div class="DBL_list_itemRB">{{speaker.hospital_name}}</div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
      <!-- 左侧手机模板——详情页 -->
      <div v-show="page==2" class="doctor_base_contentL">
        <div class="phone_title_home">
          <img width="100%" src="@/assets/phone_title.png" alt="">
          <div class="phone_title_txt text">孟志强</div>
        </div>
        <div ref='changeDateStyle' class="DBL_data">

          <div class="DBL_data_title">
            <div ref="my_bgc" class="DBL_data_titleT">
              <div class="DBL_data_titleTL">
                <img src="@/assets/ex_nav.webp" alt="">
              </div>
              <div class="DBL_data_titleTR">
                <div class="DBL_data_titleTRT">孟志强</div>
                <div class="DBL_data_titleTRB"><img width="12px" src="@/assets/auto_img/医院_hospital@2x (1).png"
                    alt="">复旦大学附属肿瘤医院</div>
              </div>
            </div>
            <div class="DBL_data_titleB"><img width="14px" src="@/assets/auto_img/未标题-1.png" alt="">2023浦江前列腺癌高峰论坛</div>
          </div>

          <div v-if="!baseData.is_date_navigation||baseData.is_date_navigation==1">
            <div class="DBL_data_tab">
              <div class="DBL_data_item">
                <span>12月17日</span>
                <img class="DBL_data_itemIcon" src="@/assets/auto_img/jiant.png" alt="">
              </div>

              <div class="DBL_data_item">
                <span>12月18日</span>
              </div>

              <div class="DBL_data_item">
                <span>12月19日</span>
              </div>

              <div class="DBL_data_item">
                <span>12月20日</span>
              </div>
            </div>

            <div class="DBL_data_All">
              <div class="DBL_data_AllD">
                <div class="DBL_data_Data">
                  <div class="DBL_data_DTitle">
                    会场：<span class="DBL_data_DMsg">第十四届上海泌尿肿瘤学术大会主会场</span>
                  </div>
                  <div class="DBL_data_DTitle">
                    环节：<span class="DBL_data_DMsg">泌尿肿瘤新时代——（一）融合与创新</span>
                  </div>
                  <div class="DBL_data_DTitle">
                    身份：<span class="DBL_data_DMsg">讲者</span>
                  </div>
                  <div class="DBL_data_DTitle task_list">
                    <div class="task_list_boxTitle">讲题：</div>
                    <div class="task_list_box">
                      <div class="task_item">
                        <div class="task_itemTime">11:30-11:55</div>
                        <div class="task_itemTXT">晚期病例询问肾组</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="DBL_data_Data">
                  <div class="DBL_data_DTitle">
                    会场：<span class="DBL_data_DMsg">第十四届上海泌尿肿瘤学术大会主会场</span>
                  </div>
                  <div class="DBL_data_DTitle">
                    环节：<span class="DBL_data_DMsg">泌尿肿瘤新时代——（一）融合与创新</span>
                  </div>
                  <div class="DBL_data_DTitle">
                    身份：<span class="DBL_data_DMsg">讲者</span>
                  </div>
                  <div class="DBL_data_DTitle task_list">
                    <div class="task_list_boxTitle">讲题：</div>
                    <div class="task_list_box">
                      <div class="task_item">
                        <div class="task_itemTime">11:30-11:55</div>
                        <div class="task_itemTXT">晚期病例询问肾组</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="baseData.is_date_navigation==2" class="DBL_data_All">
            <div class="DBL_data_AllT"><img src="@/assets/auto_img/未标题-2.png" alt=""> 2023-12-16</div>
            <div class="DBL_data_AllD">
              <div class="DBL_data_Data">
                <div class="DBL_data_DTitle">
                  会场：<span class="DBL_data_DMsg">第十四届上海泌尿肿瘤学术大会主会场</span>
                </div>
                <div class="DBL_data_DTitle">
                  环节：<span class="DBL_data_DMsg">泌尿肿瘤新时代——（一）融合与创新</span>
                </div>
                <div class="DBL_data_DTitle">
                  身份：<span class="DBL_data_DMsg">讲者</span>
                </div>
                <div class="DBL_data_DTitle task_list">
                  <div class="task_list_boxTitle">讲题：</div>
                  <div class="task_list_box">
                    <div class="task_item">
                      <div class="task_itemTime">11:30-11:55</div>
                      <div class="task_itemTXT">晚期病例询问肾组</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="DBL_data_Data">
                <div class="DBL_data_DTitle">
                  会场：<span class="DBL_data_DMsg">第十四届上海泌尿肿瘤学术大会主会场</span>
                </div>
                <div class="DBL_data_DTitle">
                  环节：<span class="DBL_data_DMsg">泌尿肿瘤新时代——（一）融合与创新</span>
                </div>
                <div class="DBL_data_DTitle">
                  身份：<span class="DBL_data_DMsg">讲者</span>
                </div>
                <div class="DBL_data_DTitle task_list">
                  <div class="task_list_boxTitle">讲题：</div>
                  <div class="task_list_box">
                    <div class="task_item">
                      <div class="task_itemTime">11:30-11:55</div>
                      <div class="task_itemTXT">晚期病例询问肾组</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 右侧配置表单 -->
      <div class="doctor_base_contentR">

        <div class="DBR_title_box DBR_B">
          <a-radio-group name="radioGroup" v-model="page" :default-value="1">
            <a-radio :value="1">
              列表页
            </a-radio>
            <a-radio :value="2">
              详情页
            </a-radio>
          </a-radio-group>
        </div>

        <div v-if="baseData.is_enable_tab&&page==1">
          <div class="DBR_title_boxBase">
            <div class="DBR_title_box">
              <div class="DBR_title_boxL">启用Tab</div>
              <div class="DBR_title_boxC">开启后在专家列表页可显示多个tab</div>
              <div class="DBR_title_boxR"><a-switch key="DBR_title_boxRC" id="DBR_title_boxRC" size="small"
                  :default-checked='baseData.is_enable_tab==1' @change="TABonChange" /></div>
            </div>
            <div v-show="baseData.is_enable_tab==1" class="DBR_data">
              <div @click="addMenu" class="DBR_data_add pointer">
                <img width="10px" src="@/assets/auto_img/编组 11@2x.png" alt="">&nbsp;&nbsp;
                添加tab
              </div>
              <div class="DBR_data_list">

                <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="baseData.menu_tab">
                  <div v-for="(item,index) in baseData.menu_tab" :key="index" class="DBR_data_item">
                    <div class="DBR_data_itemT">
                      <span class="DBR_data_title"><span class="DBR_data_must">*</span>&nbsp;<span>菜单名称：</span></span>
                      <a-input class="DBR_data_itemT_inp" v-model="item.menu_title" placeholder="请输入" :maxLength='8'
                        :suffix="item.menu_title?item.menu_title.length+'/8':'0/8'" />
                      <span style="flex:1"></span>
                      <span class="pointer">
                        <img width="16px" src="@/assets/base_img/移动@2x.png" alt="">&nbsp;&nbsp;
                        <img width="16px" @click='delateItem(index)' src="@/assets/base_img/video_delate.png" alt="">
                      </span>
                    </div>
                    <div class="DBR_data_itemB">
                      <span class="DBR_data_title"><span style="opacity:0"
                          class="DBR_data_must">*</span>&nbsp;<span>匹配身份：</span></span>
                      <a-checkbox-group class="DBR_data_itemB_select" v-model="item.identity" @change="onChange">
                        <a-checkbox v-for="(item,index) in identityList" :key="index" :value="item.identity">
                          {{item.title}}
                        </a-checkbox>
                      </a-checkbox-group>
                    </div>
                  </div>
                </draggable>

              </div>
            </div>
          </div>

          <div class="DBR_title_boxBase">
            <div class="DBR_title_box">
              <div class="DBR_title_boxL">页面样式</div>
              <div class="DBR_title_boxC">开启后可定义页面背景</div>
              <div class="DBR_title_boxR"><a-switch size="small" key="DBR_title_boxRD" id="DBR_title_boxRD"
                  :default-checked='baseData.is_enable_list_style==1' @change="PAGEonChange" /></div>
            </div>
            <div v-show="baseData.is_enable_list_style==1" class="DBR_data">
              <a-radio-group name="radioGroup" @change="changeStyleFN" defaultValue v-model="baseData.list_style">
                <a-radio :value="'1'">
                  使用全局背景色
                </a-radio>
                <a-radio :value="'2'">
                  使用全局背景图
                </a-radio>
                <a-radio :value="'3'">
                  图片
                </a-radio>
              </a-radio-group>
            </div>
            <div v-if="baseData.is_enable_list_style==1&&baseData.list_style==3" class="DBR_data">
              <div class="DBR_data_myimg">
                <upload :image='baseData.list_style_img_path' @uploadOk='list_upload' :showDelate='true'
                  @delateDate='list_uploadDelate' :height="'70px'" :width='"140px"'> </upload>
              </div>
            </div>
          </div>

        </div>

        <div v-if="baseData.is_enable_tab&&page==2">

          <div class="DBR_title_boxBase">
            <div class="DBR_title_box">
              <div class="DBR_title_boxL">页面样式</div>
              <div class="DBR_title_boxC">开启后可定义页面背景</div>
              <div class="DBR_title_boxR">
                <a-switch size="small" key="DBR_title_boxRA" id="DBR_title_boxRA"
                  :default-checked='baseData.is_enable_detail_style==1' @change="STYLEChange" />
              </div>
            </div>
            <div v-show="baseData.is_enable_detail_style==1" class="DBR_data">
              <a-radio-group name="radioGroup" @change="changeDetailStyle" v-model="baseData.detail_style">
                <a-radio :value="'1'">
                  使用全局背景色
                </a-radio>
                <a-radio :value="'2'">
                  使用全局背景图
                </a-radio>
                <a-radio :value="'3'">
                  图片
                </a-radio>
              </a-radio-group>
            </div>
            <div v-if="baseData.is_enable_detail_style==1&&baseData.detail_style==3" class="DBR_data">
              <div class="DBR_data_myimg">
                <upload :image='baseData.detail_style_img_path' @uploadOk='detail_upload' :showDelate='true'
                  @delateDate='detail_uploadDelate' :height="'70px'" :width='"140px"'> </upload>
              </div>
            </div>
          </div>

          <div class="DBR_title_boxBase">
            <div class="DBR_title_box">
              <div class="DBR_title_boxL">个人信息背景</div>
            </div>
            <div class="DBR_data">
              <div v-if="page==2" class="DBR_data_myimg">
                <upload :image='baseData.personal_background_path' @uploadOk='personal_upload' :showDelate='true'
                  @delateDate='personal_uploadDelate' :height="'70px'" :width='"140px"'> </upload>
              </div>
            </div>
          </div>

          <div class="DBR_title_boxBase bottom_none">
            <div class="DBR_title_box">
              <div class="DBR_title_boxL">日期导航</div>
              <span style="flex:1"></span>
              <div class="DBR_title_boxR"><a-switch size="small" key="DBR_title_boxRB" id="DBR_title_boxRB"
                  :default-checked='baseData.is_date_navigation==1' @change="DATEonChange" /></div>
            </div>
          </div>

        </div>

        <div class="DBR_dc_btn">
          <a-button @click="onClose">取消</a-button>
          <a-button @click="sendForm" type="primary">
            确定
          </a-button>
        </div>

      </div>
    </div>

    <div v-show="basePage==2" class="doctor_base_content noFlex">
      <a-table @change="handleTableChangeB" :pagination="ipaginationB"
        :rowKey='record=>record.specialist_task_relation_id' :columns="columnsB" :data-source="dataB">

        <span slot="name" slot-scope="text, record">
          <span class="ipaginationB_name">{{record.name}}</span>
        </span>

        <span slot="identity" slot-scope="text, record">
          <span v-for="(item,index) in identityList" :key="index" v-show="record.identity==item.identity"
            class="ipaginationB_identity">{{item.title}}</span>
        </span>

        <span slot="task_date" slot-scope="text, record">
          <span>{{record.task_date +' '+ record.begin_time + '-' + record.end_time}}</span>
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click='workPlaceB(record)'> 编辑 </a>
          <a-divider type="vertical" />
          <a @click="delateTaskFn(record)" style="margin-left:5px">删除 </a>
        </span>

      </a-table>
    </div>

    <div v-show="basePage==3" class="doctor_base_content noFlex">
      <a-table @change="handleTableChangeA" :pagination="ipaginationA" :rowKey='record=>record.task_id'
        :columns="columnsA" :data-source="dataA">

        <span slot="begin_time" slot-scope="text, record">
          <span>{{record.begin_time}}</span><span v-if="record.end_time"> - {{record.end_time}}</span>
        </span>

        <div slot="other" slot-scope="text, record">
          <div v-for="(item,index) in record.other" :key="index">{{item.identity_text+':'+item.name}};</div>
        </div>

        <span slot="module_title" slot-scope="text, record">
          <span>{{record.module_title}}</span>
        </span>

        <span slot="presenter" slot-scope="text, record">
          <span>{{record.presenter}}</span>
        </span>

      </a-table>
    </div>
    <doctorUpload :meetData='meetData' @uploadOk='uploadOk' ref="doctorUpload"></doctorUpload>
    <doctorChange :meetData='meetData' :identityList='identityList' @getTaskList='getTaskListFN' ref="doctorChange">
    </doctorChange>
  </div>
</template>

<script>
import { geiVideoListAll, getTaskList, getIdentity, getTaskDetail, meetingDetail, getTaskConfig, createTaskConfig, getTaskCheck, delateTask } from '@/api/meetApi';
import upload from '@/components/upload.vue';
import doctorUpload from './doctorUpload.vue';
import draggable from "vuedraggable";
import doctorChange from './doctorChange.vue';
const columnsA = [
  {
    title: "时间",
    dataIndex: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    key: "begin_time",
    width: 150,
    align: "left",
  },
  {
    title: "讲者",
    dataIndex: "speaker",
    scopedSlots: { customRender: "speaker" },
    width: 80,
    key: "speaker",
    align: "left",
  },
  {
    title: "讲题",
    dataIndex: "task_title",
    key: "task_title",
    scopedSlots: { customRender: "task_title" },
    align: "left",
  },
  {
    title: "环节",
    key: "module_title",
    scopedSlots: { customRender: "module_title" },
    align: "left",
  },
  {
    title: "主持",
    key: "presenter",
    scopedSlots: { customRender: "presenter" },
    align: "left",
  },
  {
    title: "其他",
    key: "other",
    scopedSlots: { customRender: "other" },
    align: "left",
  },
];

const columnsB = [
  {
    title: "讲者",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
    key: "name",
    width: 80,
    align: "left",
  },
  {
    title: "时间",
    dataIndex: "task_date",
    scopedSlots: { customRender: "task_date" },
    key: "task_date",
    align: "left",
  },
  {
    title: "身份",
    dataIndex: "identity",
    key: "identity",
    scopedSlots: { customRender: "identity" },
    align: "left",
  },
  {
    title: "直播",
    dataIndex: "title",
    key: "title",
    scopedSlots: { customRender: "title" },
    align: "left",
  },
  {
    title: "环节",
    dataIndex: "module_title",
    key: "module_title",
    scopedSlots: { customRender: "module_title" },
    align: "left",
  },
  {
    title: "讲题",
    dataIndex: "task_title",
    key: "task_title",
    scopedSlots: { customRender: "task_title" },
    align: "left",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left",
  },
];

export default {
  components: { upload, doctorUpload, doctorChange, draggable },
  data() {
    return {
      video_data: [],
      baseData: {},
      meetingData: {},
      basePage: 1,
      page: 1,
      dataList: {
        "A": [
          {
            "id": 228,
            "name": "安志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "安达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        "B": [
          {
            "id": 228,
            "name": "包志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "包达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        "C": [
          {
            "id": 228,
            "name": "蔡志康",
            "hospital_name": "上海市浦东新区公利医院",
            "head_img": "/uploads/070e101b64e5ab2c147faf977889baf1.jpeg",
            "name_Initial": "C",
            "sort": 0
          },
          {
            "id": 251,
            "name": "曹达龙",
            "hospital_name": "复旦大学附属肿瘤医院",
            "head_img": "/uploads/ebe05369e67bb82f7c7f04a002efb07d.jpg",
            "name_Initial": "C",
            "sort": 0
          },
        ],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: [],
      },
      identityList: [],
      meetData: {},
      // 
      // 
      // 
      columnsA,
      dataA: [],
      ipaginationA: {
        size: "large",
        current: 1,
        pageSize: 9999,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: true, // 只有一页时是否隐藏分页器 
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
      // 
      // 
      // 
      keyword: '',
      columnsB,
      dataB: [],
      ipaginationB: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器 
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    }
  },
  created() {
    this.getVideList()
    this.getIdentityFN()
    this.getTaskConfigFn()
    this.getMeetData()
  },
  methods: {
    async getMeetData() {
      let data = await meetingDetail({ id: this.$route.query.meeting_id });
      this.meetData = data.payload.meeting_data;
      console.log(data)
    },
    changeStyleFN() {
      this.$refs.changeListStyle.style.background = "unset";
      this.$refs.changeListStyle.style.backgroundColor = "unset";
      if (this.baseData.is_enable_list_style == 1) {
        if (this.baseData.list_style == 1) {
          console.log(this.$refs)
          this.$refs.changeListStyle.style.backgroundColor = this.meetingData.background_color;
        }
        if (this.baseData.list_style == 2) {
          this.$refs.changeListStyle.style.background = 'url(' + this.meetingData.background_img_path + ')';
        }
        if (this.baseData.list_style == 3) {
          this.$refs.changeListStyle.style.background = 'url(' + this.baseData.list_style_img_path + ')';
        }
      } else {
        this.$refs.changeListStyle.style.backgroundColor = "#fff";
      }
    },
    changeDetailStyle() {
      this.$refs.changeDateStyle.style.background = "unset";
      this.$refs.changeDateStyle.style.backgroundColor = "unset";
      this.$refs.my_bgc.style.background = "unset";

      if (this.baseData.is_enable_detail_style == 1) {
        if (this.baseData.detail_style == 1) {
          this.$refs.changeDateStyle.style.backgroundColor = this.meetingData.background_color;
        }
        if (this.baseData.detail_style == 2) {
          this.$refs.changeDateStyle.style.background = 'url(' + this.meetingData.background_img_path + ')';
        }
        if (this.baseData.detail_style == 3) {
          this.$refs.changeDateStyle.style.background = 'url(' + this.baseData.detail_style_img_path + ')';
        }
      } else {
        this.$refs.changeDateStyle.style.backgroundColor = "#fff";
      }

      if (this.baseData.personal_background_path) {
        this.$refs.my_bgc.style.background = 'url(' + this.baseData.personal_background_path + ')';
      } else {
        this.$refs.my_bgc.style.backgroundColor = "#fff";
      }

    },
    async getVideList() {
      let data = await geiVideoListAll({
        meeting_id: this.$route.query.meeting_id,
        per_page: 9999 //传个大值，获取所有
      })
      this.video_data = data.payload.data
      this.video_data=this.video_data.filter(item => item.type === 1);
    },
    endEventFN() { },
    async getTaskConfigFn() {
      let data = await getTaskConfig({ meeting_id: this.$route.query.meeting_id })
      this.baseData = data.payload.data
      this.meetingData = data.payload.meeting_data
      this.changeStyleFN()
      this.changeDetailStyle()
      console.log(this.baseData)
    },
    async getTaskListFN() {
      let sendData = {
        keyword: this.keyword,
        meeting_id: this.$route.query.meeting_id,
        page: this.ipaginationB.current,
        per_page: this.ipaginationB.pageSize
      }
      let data = await getTaskList(sendData)
      this.dataB = data.payload.data
      this.ipaginationB.total = data.payload.page.total
      console.log(data)
    },
    async getTaskCheckFN(live_id) {
      let data = await getTaskCheck({ meeting_id: this.$route.query.meeting_id, live_id: live_id })
      this.dataA = data.payload.data
    },
    onSearch() {
      this.ipaginationB.current = 1
      this.getTaskListFN()
    },
    addMenu() {
      this.baseData.menu_tab.push({
        "menu_title": "菜单名称",
        "identity": [],
      },)
    },
    delateTaskFn(data) {
      let _this = this
      this.$confirm({
        title: '确定删除吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          console.log(data)
          let res = await delateTask({ specialist_task_relation_id: data.specialist_task_relation_id })
          _this.$message.success('删除成功')
          _this.getTaskListFN()
          console.log(res)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    async getIdentityFN() {
      let identityList = await getIdentity()
      this.identityList = identityList.payload.data
    },
    uploadOk() {
      this.keyword = ''
      this.ipaginationB.current = 1
      this.getTaskListFN()
    },
    delateItem(index) {
      this.baseData.menu_tab.splice(index, 1)
    },
    onChange(checked) { },
    STYLEChange(data) {
      if (data) {
        this.baseData.is_enable_detail_style = 1
      }
      else {
        this.baseData.is_enable_detail_style = 2
      }
      this.changeDetailStyle()
      console.log(this.baseData)
    },
    DATEonChange(data) {
      if (data) {
        this.baseData.is_date_navigation = 1
      }
      else {
        this.baseData.is_date_navigation = 2
      }
      console.log(this.baseData)
    },
    TABonChange(data) {
      if (data) {
        this.baseData.is_enable_tab = 1
      }
      else {
        this.baseData.is_enable_tab = 2
      }
      console.log(this.baseData)
    },
    PAGEonChange(data) {
      if (data) {
        this.baseData.is_enable_list_style = 1
      }
      else {
        this.baseData.is_enable_list_style = 2
      }
      this.changeStyleFN()
    },
    async changebasePage(page) {
      this.basePage = page;
      if (page == 2) {
        await this.getTaskListFN()
      } else if (page == 3) {
        if (this.video_data && this.video_data[0]) {
          await this.getTaskCheckFN(this.video_data[0].id)
        } else {
          this.$message.error("无视频信息!")
        }
      }

    },
    showUPCheck() {
      this.$refs.doctorUpload.showModalCheck()
    },
    showUP() {
      this.$refs.doctorUpload.showModal()
    },
    doctorAdd() {
      this.$refs.doctorChange.showDrawer()
    },
    // 
    handleTableChangeB(page) {
      this.ipaginationB = page;
      this.getTaskListFN();
    },
    workPlaceB(data) {
      this.$refs.doctorChange.showDrawer(data.specialist_task_relation_id)
    },
    delateB() { },
    onClose() {
      //刷新页面
      this.$router.go(0)
    },
    async sendForm() {
      let data = await createTaskConfig(this.baseData)
      this.$message.success("保存成功！")
      console.log(data)
    },
    // 
    handleTableChangeA(page) {
      // this.ipaginationA = page;
      // this.getTaskListFN();
    },
    workPlaceA() { },
    delateA() { },
    personal_upload(str) {
      if (str.status == 1) {
        this.baseData.personal_background = str.payload.id
        this.baseData.personal_background_path = str.payload.file_url
        this.changeDetailStyle()
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    personal_uploadDelate() {
      this.baseData.personal_background = ''
      this.baseData.personal_background_path = ''
      this.changeDetailStyle()
    },

    list_upload(str) {
      if (str.status == 1) {
        this.baseData.list_style_img = str.payload.id
        this.baseData.list_style_img_path = str.payload.file_url
        this.changeStyleFN()
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    list_uploadDelate() {
      this.baseData.list_style_img = ''
      this.baseData.list_style_img_path = ''
    },

    detail_upload(str) {
      if (str.status == 1) {
        this.baseData.detail_style_img = str.payload.id
        this.baseData.detail_style_img_path = str.payload.file_url
        this.changeDetailStyle()
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    detail_uploadDelate() {
      this.baseData.detail_style_img = ''
      this.baseData.detail_style_img_path = ''
      this.changeDetailStyle()
    },
  }
}
</script>

<style lang="less" scoped>
.task_list_box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.task_list {
  display: flex;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 13px;
  color: #232323;
  text-align: left;
  font-style: normal;
  .task_list_boxTitle {
  }
  .task_item {
    flex: 1;
    border-bottom: 1px dashed #eff0f2;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .task_item:last-child {
    border-bottom: 0 solid #000;
    padding-bottom: 0px;
    margin-bottom: 0;
  }
  .task_itemTime {
    margin-bottom: 3px;
    font-weight: 500;
    line-height: 19px;
    font-size: 13px;
    color: #232323;
  }
  .task_itemTXT {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 13px;
    color: #818181;
    text-align: left;
    font-style: normal;
  }
}
.DBL_data {
  // min-height: 667px;
  height: calc(100% - 81px);
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-size: 100% 100%;
  // overflow: hidden;
  .DBL_data_All {
    margin-bottom: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    border: 1px solid #efefef;
    .DBL_data_AllD {
      padding: 10px 12px;
    }
    .DBL_data_DTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #818181;
      line-height: 18px;
      text-align: left;
      margin-bottom: 8px;
      font-style: normal;
      .DBL_data_DMsg {
        color: #232323;
      }
    }
    .DBL_data_Data {
      border-bottom: 1px solid #eff0f2;
      margin-bottom: 9px;
    }
    .DBL_data_Data:last-child {
      border-bottom: 0px solid #eff0f2;
      margin-bottom: 0;
    }
    .DBL_data_AllT {
      height: 36px;
      img {
        width: 14px;
        margin-right: 6px;
      }
      background: rgba(0, 0, 0, 0.03);
      display: flex;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      padding: 0 12px;
      align-items: center;
    }
  }
  .DBL_data_tab {
    padding: 12px 0;
    padding-top: 0;
    width: 100%;
    white-space: nowrap;
    // overflow: auto;
    overflow: hidden;
    .DBL_data_item {
      margin-right: 12px;
      span {
        display: flex;
        height: 100%;
        align-items: center;
      }
      position: relative;
      display: inline-block;
      height: 30px;
      padding: 5px 20px;
      font-weight: 400;
      line-height: 12px;
      font-size: 14px;
      background: #ffffff;
      border-radius: 15px;
      border: 1px solid #f4f4f4;
      color: #818181;
      .DBL_data_itemIcon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        height: 5px;
        margin: auto;
      }
    }
  }
  padding: 12px;
  .DBL_data_title {
    overflow: hidden;
    margin-bottom: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    border: 1px solid #f4f4f4;
    .DBL_data_titleT {
      padding: 12px;
      background-size: 100% 100%;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      background-size: 100% 100%;
      min-height: 104px;
      display: flex;
      align-items: center;
      .DBL_data_titleTL {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        overflow: hidden;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .DBL_data_titleTR {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .DBL_data_titleTRT {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #232323;
        line-height: 22px;
        text-align: left;
        margin-bottom: 9px;
        font-style: normal;
      }
      .DBL_data_titleTRB {
        img {
          margin-right: 6px;
        }
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #232323;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        display: flex;
        align-items: center;
      }
    }
    .DBL_data_titleB {
      border-top: 1px solid #eff0f2;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      display: flex;
      padding: 8px 12px;
      min-height: 40px;
      align-items: center;
      img {
        margin-right: 6px;
      }
    }
  }
}
.DBR_dc_btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin-right: 24px;
  }
}
.ipaginationB_name {
  font-weight: 500;
  font-size: 14px;
  color: #222222;
}
.DBL_list_TAB {
  height: 48px;
  display: flex;
  .DBL_list_TAB_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .ckecked {
      color: #1890ff;
    }
    .DBL_list_TABL_line {
      display: inline-block;
      width: 20px;
      border-radius: 2px;
      height: 2px;
      background-color: #1890ff;
      position: absolute;
      width: 20px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 10px;
    }
  }
}
.doctor_title_box {
  display: flex;
  justify-content: space-between;
  :deep(.onSearchBox .ant-btn-primary) {
    background: #f5f7fa;
    border: 1px solid #e5e6eb;
    color: #7e8081;
    box-shadow: unset;
  }
}
.doctor_title_boxR {
  display: flex;
  button {
    margin-left: 10px;
  }
  .doc_name_title {
    display: flex;
    min-width: 480px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }
}
.doctor_base {
  padding: 24px;
}
.doctor_base_title {
  display: flex;
  padding: 4px;
  background: #f4f5f6;
  border-radius: 4px;
  width: 312px;
  justify-content: space-between;
  div {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    border-radius: 2px;
  }
  .checked {
    color: #1890ff;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
}
.doctor_base_content {
  display: flex;
  margin-top: 24px;
  margin-bottom: 70px;
  width: 100%;
  .doctor_base_contentL {
    margin-right: 40px;
    width: 375px;
    min-height: 667px;
    border: 1px solid #e5e6eb;
    border-radius: 8px;
  }
  .doctor_base_contentR {
    .DBR_data_itemT_inp,
    .ant-input {
      width: 180px;
      color: #222222;
    }
    :deep(.ant-input) {
      color: #222222;
    }
    .ant-checkbox-wrapper {
      color: #222222;
    }
    .DBR_data_item {
      padding: 16px;
      font-weight: 400;
      font-size: 14px;
      color: #585858;
      min-height: 112px;
      background: #f9fafc;
      border-radius: 2px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
    }
    .DBR_data_item:last-child {
      margin-bottom: 0;
    }
    .DBR_data_title {
      margin-right: 12px;
    }
    .DBR_data_must {
      color: red;
    }
    .DBR_data_itemT {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    .DBR_data_myimg {
      width: 160px;
      height: 90px;
    }
    .DBR_data {
      .DBR_data_item {
        display: flex;
      }
      padding-bottom: 24px;
      .DBR_data_add {
        width: 160px;
        margin-bottom: 16px;
        height: 40px;
        font-weight: 400;
        font-size: 12px;
        color: #222222;
        line-height: 17px;
        border-radius: 4px;
        border: 1px solid #e5e6eb;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    flex: 1;
    max-width: 560px;
    .DBR_B {
      border-bottom: 1px solid #eff0f2;
    }
    .DBR_title_boxBase {
      border-bottom: 1px solid #eff0f2;
    }
    .bottom_none {
      border-bottom: 0px solid #000;
    }
    .DBR_title_box {
      padding: 24px 0;
      display: flex;
      align-items: center;
    }
    .DBR_title_boxL {
      font-weight: 400;
      font-size: 14px;
      color: #222222;
    }
    .DBR_title_boxC {
      flex: 1;
      font-weight: 400;
      font-size: 13px;
      color: #86909d;
      margin-left: 12px;
    }
  }
  .DBL_list {
    // background: url("~@/assets/kv.jpg");
    background-size: 100% 100%;
    padding: 10px 12px;
    .DBL_list_inp {
      :deep(.ant-input) {
        background: #ffffff;
        border-radius: 20px;
      }
    }
    .DBL_list_All {
      background-color: #fff;
      .DBL_list_Base_RBig {
        position: absolute;
        height: 90%;
        right: -12px;
        top: 5%;
        font-weight: 400;
        font-size: 10px;
        color: #a9a9a9;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .DBL_list_BaseSelect {
        color: #232323;
      }
      position: relative;
      border: 1px solid #f1f3f4;
      border-radius: 12px;
      margin: 10px 0;
    }
    .DBL_list_Base {
      .DBL_list_BaseBig {
        position: absolute;
        left: 16px;
        top: 2px;
        font-weight: 400;
        font-size: 12px;
        color: #a9a9a9;
      }
      position: relative;
      height: 100%;
      padding: 0 16px;
      .DBL_list_item {
        .DBL_list_itemL {
          width: 56px;
          height: 56px;
          border-radius: 56px;
          overflow: hidden;
          margin-right: 12px;
        }
        .DBL_list_itemR {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .DBL_list_itemRT {
          font-weight: 600;
          font-size: 14px;
          color: #232323;
        }
        .DBL_list_itemRB {
          font-weight: 400;
          font-size: 12px;
          color: #888888;
        }
        display: flex;
        padding: 17px 0;
        height: 88px;
        border-bottom: 1px solid #f1f3f4;
      }
    }
  }
}
.noFlex {
  display: block;
  margin-bottom: 0;
}
</style>

<style>
.DBR_data_itemB {
  display: flex;
}
.DBR_data_itemB_select {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.DBR_data_itemB_select label {
  margin-right: 8px;
  margin-left: 0 !important;
}
</style>