<template>
  <div class="videoList_MH">
    <div class="page_menu_item p24">
      <a-button @click="addVideo" type="primary"> 新增直播 </a-button>
      <div style="flex:1"></div>
      <a-input-search v-model="keyword" class="onSearchBox" placeholder="请输入关键字" enter-button="搜索" @search="onSearch" />
    </div>
    <a-table class="p24" :loading="loading" @change="video_handleTableChange" :pagination="video_pagination"
      :rowKey='record=>record.id' :columns="video_columns" :data-source="video_data">

      <span class="menu_name_box" slot="name" slot-scope="text, record">
        <img v-if="record.img" width="150px" height="85px" :src="record.img" alt="">
        <span class="menu_name">{{record.name}}</span>
      </span>

      <span slot="status" slot-scope="text, record">
        <span class="status status_wks" v-if="record.status==0"><img src="@/assets/auto_img/wks.png" alt="">
          未开始</span>
        <span class="status status_zbz" v-if="record.status==1"><img src="@/assets/auto_img/zbz.png" alt="">
          直播中</span>
        <span class="status status_yjs" v-if="record.status==2"><img src="@/assets/auto_img/yjs.png" alt="">
          已结束</span>
      </span>

      <span slot="type" slot-scope="text, record">
        <span v-if="record.type==1" class="page_type">直播</span>
        <span v-if="record.type==3" class="page_type">议程</span>
        <span v-if="record.type==2" class="page_type">视频</span>
        <span v-if="record.type==4" class="page_type">外链</span>
      </span>

      <span slot="begin_time" slot-scope="text, record">
        <span>{{ record.begin_time?record.begin_time: '未填'|time('YYYY.MM.DD HH:mm') }} </span>
      </span>

      <span slot="end_time" slot-scope="text, record">
        <span>{{ record.end_time?record.end_time: '未填'|time('YYYY.MM.DD HH:mm') }} </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click='video_workPlace(record)'> 配置 </a>
        <a-divider type="vertical" />
        <a @click='shareVideoFn(record)'> 推广 </a>
        <a-divider type="vertical" />
        <a @click="video_delate(text)"> 删除 </a>
      </span>
    </a-table>
    <video_Modal ref="video_modal" class="video_modal" @addList='getVideList' />
    <Preview ref="preview" />

  </div>
</template>

<script>

const video_columns = [
  {
    title: "直播名称",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
    key: "title",
    width: 350,
    align: "left",
  },
  {
    title: "类型",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    key: "type",
    align: "left",
  },
  {
    title: "开始时间",
    key: "begin_time",
    scopedSlots: { customRender: "begin_time" },
    align: "left",
  },
  {
    title: "结束时间",
    key: "end_time",
    scopedSlots: { customRender: "end_time" },
    align: "left",
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    key: "status",
    align: "left",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left",
  },
];
import video_Modal from "@/views/videoList/components/Modal.vue";
import Preview from "@/components/Preview.vue";

import { geiVideoListAll, getViewList, delateViewData, delateVideo, exportExcel } from "@/api/meetApi";
export default {
  components: { video_Modal, Preview },
  data() {
    return {
      // 
      // 
      // 
      keyword: '',
      video_data: [],
      video_columns,
      video_pagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      }
    }
  },
  created() {
    this.getVideList()
  },
  methods: {
    video_addList(data) {
      this.getVideList()
    },
    video_change(data) {
      this.$refs.modal.change(data);
    },
    video_workPlace(data) {
      this.$router.push("/videoSet?id=" + data.id + '&meeting_id=' + this.$route.query.meeting_id)
    },
    video_delate(msg) {
      var _this = this;

      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let data = await delateVideo({
            live_id: msg.id
          })
          _this.$message.success('删除成功！')
          _this.getVideList()
        },
        onCancel() { },
      });
    },
    async getVideList() {
      this.loading = true;

      let data = await geiVideoListAll({
        meeting_id: this.$route.query.meeting_id,
        page: this.video_pagination.current,
        keyword: this.keyword
      })
      console.log(data)
      this.video_data = data.payload.data
      // this.video_pagination.current = data.payload.page.current_page
      this.video_pagination.total = data.payload.page.total
      this.loading = false;


    },
    onSearch() {
      this.video_pagination.current = 1
      this.getVideList()
    },
    video_handleTableChange(data) {
      this.video_pagination.current = data.current
      this.getVideList()
    },
    addVideo() { this.$refs.video_modal.showModal() },
    shareVideoFn(data) {
      this.$refs.preview.show('live', data.id);
    },
  }
}
</script>

<style lang="less" scoped>
.videoList_MH {
  padding: 24px;
}
.page_menu_item {
  display: flex;
  padding-bottom: 20px;
  .onSearchBox {
    width: 280px;
  }
}
.page_type {
  display: inline-block;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 2px;
  border: 1px solid rgba(24, 144, 255, 0.1);
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #1890ff;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  padding: 0 6px;
}
.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
</style>