<template>
  <div class="add_cloub_booth">
    <a-modal title="新增" ok-text="确认" cancel-text="取消" width='700px' destroyOnClose :visible="visible"
      :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="名称">
          <a-input v-model="ruleForm.title" type="text" />
        </a-form-model-item>
      </a-form-model>

      <!-- <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="所属会议">
          <a-select style="width: 100%" v-model="ruleForm.type">
            <a-select-option disabled value="">
              请选择
            </a-select-option>
            <a-select-option :value="1">
              首页
            </a-select-option>
            <a-select-option :value="2">
              列表页
            </a-select-option>
            <a-select-option :value="3">
              详情页
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="是否显示">
          <a-select style="width: 100%" v-model="ruleForm.type">
            <a-select-option disabled value="">
              请选择
            </a-select-option>
            <a-select-option :value="1">
              首页
            </a-select-option>
            <a-select-option :value="2">
              列表页
            </a-select-option>
            <a-select-option :value="3">
              详情页
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model> -->

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="视频链接">
          <a-input v-model="ruleForm.video_link" type="text" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="视频封面">
          <upload :image='ruleForm.cover_path' @uploadOk='uploadOk' :showDelate='true' @delateDate='delateDate'
            :height="'120px'" :width="'120px'">
          </upload>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="厂商logo">
          <upload :image='ruleForm.icon_path' @uploadOk='uploadOk2' :showDelate='true' @delateDate='delateDate2'
            :height="'120px'">
          </upload>
        </a-form-model-item>
      </a-form-model>

      <a-form-model layout='horizontal' ref="ruleForm" :model="ruleForm" v-bind="layout">
        <a-form-model-item has-feedback label="详情">
          <div style="min-height: 400px;height: 400px;">
            <Editor :form='Editor' @input='input'></Editor>
          </div>
        </a-form-model-item>
      </a-form-model>

    </a-modal>
  </div>
</template>


<script>
import upload from '@/components/upload.vue';
import { createVideoCreate } from "@/api/meetApi";
import Editor from '@/components/Editor.vue';
export default {
  components: { upload, Editor },
  data() {
    return {
      Editor: { data: { desc: '' } },
      visible: false,
      confirmLoading: false,
      ruleForm: {
        title: "",
        begin_time: null,
        end_time: null,
        type: "1",
        meeting_id: this.$route.query.meeting_id,
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
    };
  },
  methods: {
    delateDate(data) {
      console.log(3456)
    },
    uploadOk(data) {
      if (data.status == 1) {
        this.ruleForm.cover_path = data.payload.path
        this.ruleForm.cover = data.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },
    delateDate2(data) {
      console.log(3456)
    },
    uploadOk2(data) {
      if (data.status == 1) {
        this.ruleForm.icon_path = data.payload.path
        this.ruleForm.icon = data.payload.id
      } else {
        alert('上传失败，请刷新重试！')
      }
    },

    input(str) {
      this.ruleForm.content = str
    },
    async change(data) {
      console.log(data)
      await this.clear();
      if (data) {
        this.Editor.data.desc = data.content
        this.ruleForm = JSON.parse(JSON.stringify(data));
      }
      this.ruleForm.meeting_id = this.$route.query.meeting_id;
      this.visible = true;
      this.$forceUpdate()
      console.log(this.Editor)
    },
    showModal() {
      this.clear()
      console.log(this.ruleForm)
      this.visible = true;
    },
    async handleOk(e) {
      this.ruleForm.meeting_id = this.$route.query.meeting_id
      if (!this.ruleForm.title) {
        return this.$message.error("名称必填！");
      }

      console.log(this.ruleForm)
      this.confirmLoading = true;
      try {
        let data = await createVideoCreate(this.ruleForm);
        console.log(this.ruleForm);
        this.$emit("getListCloudBooth", { meeting_id: this.ruleForm.meeting_id });
        this.clear();
        this.visible = false;
        this.confirmLoading = false;
        this.$message.success("创建成功！");
        return this.$route.push(0)
      } catch {
        this.confirmLoading = false;
      }
    },
    handleCancel(e) {
      var _this = this;
      _this.visible = false;
      _this.clear();
      // for (const key in this.ruleForm) {
      //   if (this.ruleForm[key]) {
      //     return this.$confirm({
      //       title: "确定退出吗！",
      //       content: "退出不会保留修改内容！",
      //       okText: "确定",
      //       okType: "danger",
      //       cancelText: "取消",
      //       onOk() {
      //         _this.visible = false;
      //         _this.clear();
      //       },
      //       onCancel() { },
      //     });
      //   }
      // }
    },
    clear() {
      for (const key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
      this.ruleForm.meeting_id = this.$route.query.meeting_id;
      this.ruleForm.type = "cloud";
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  span {
    font-size: 24px;
    font-weight: 500px;
  }
}
:deep(.ant-calendar-picker) {
  width: 100%;
}
:deep(.ant-calendar-picker-icon) {
  // top: 30%;
}
</style> 