<template>
  <div class="cloudBooth">

    <div class="loginList_title">
      <div class="loginList_title_select">
        <div class="loginList_title_T">表单标志</div>
        <a-select v-model="searchData.form_id" @change='changeLogin' placeholder="请选择">
          <a-select-option v-for="item in formMarkList" :key="item.id" :value="item.id">
            {{item.form_mark||'未命名表单'}}
          </a-select-option>
        </a-select>
      </div>
      <a-input-search v-model="searchData.keyword" class="onSearchBox" placeholder="请输入关键字" enter-button="搜索"
        @search="onSearch" />
      <div style="flex:1"></div>
      <a-button @click='getExcel' type="primary">
        导出数据
      </a-button>
      <a-button @click="clearDataAll" type="">
        清空数据
      </a-button>
    </div>

    <a-table :scroll="{ x: 1300 }" :loading="loading" :pagination="ipagination" @change="handlePageChange"
      :rowKey="record=>record.id" :columns="columns" :data-source="data">

      <span v-for="(item,num_a) in columns" :key="item.key" :slot="item.key" slot-scope="text, record">

        <span v-if="item.key=='send_time'">{{record.created_at}}</span>

        <span v-if="item.key!='send_time'&&item.key!='action'">

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='checkBoxWork'">
            <span class="noJG" v-for="(item_a,index_a) in record.sign_in_data_detail[num_a-1].value"
              :key="index_a">{{item_a}} <span>,</span></span>
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='timePickerWork'">
            {{Date.now(record.sign_in_data_detail[num_a-1].value)/1000|time('YYYY-MM-DD HH:mm')}}
          </span>

          <span class="text2"
            v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='articleWork'"
            v-html="record.sign_in_data_detail[num_a-1].value">
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='cityWork'">
            <span class="noJG" v-for="(item_a,index_a) in record.sign_in_data_detail[num_a-1].value"
              :key="index_a">{{item_a}} <span>,</span></span>
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='selectWork'">
            {{record.sign_in_data_detail[num_a-1]?record.sign_in_data_detail[num_a-1].value:'未填写'}}
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='inputWork'">
            {{record.sign_in_data_detail[num_a-1]?record.sign_in_data_detail[num_a-1].value:'未填写'}}
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='textareaWork'">
            {{record.sign_in_data_detail[num_a-1]?record.sign_in_data_detail[num_a-1].value:'未填写'}}
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='radioWork'">
            {{record.sign_in_data_detail[num_a-1]?record.sign_in_data_detail[num_a-1].value:'未填写'}}
          </span>

          <span v-if="record.sign_in_data_detail[num_a-1]&&record.sign_in_data_detail[num_a-1].type=='upload_fileWork'">
            <span v-for="(item_a,index_a) in record.sign_in_data_detail[num_a-1].value" class="noJG" :key="index_a">
              <a :href="item_a.file_url" target="_blank">{{item_a.file_url}}</a>
              <span>,</span>
            </span>
          </span>

        </span>

        <span v-if="item.key=='action'">
          <a @click='change(record)'> 查看 </a>
          <a-divider type="vertical" />
          <a @click="delate(record)"> 删除 </a>
        </span>
      </span>

    </a-table>
    <loginForm ref="loginForm" :formwork="formList"></loginForm>
  </div>
</template>

<script> 
import loginForm from './login.vue';
import moment from 'moment';
import {
  formMark,
  formSignin,
  deleteSignin
} from "@/api/meetApi";
const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "类型",
    dataIndex: "form_type",
    scopedSlots: { customRender: "form_type" },
    key: "form_type",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    scopedSlots: { customRender: "created_at" },
    key: "created_at",
    align: "center",
  },
  {
    title: "内容",
    key: "dataBox",
    scopedSlots: { customRender: "dataBox" },
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  components: { loginForm },
  data() {
    return {
      current: ['page'],
      formMarkList: [],
      loading: false,
      data: [],
      columns,
      //分页参数
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
      formList: [],
      searchData: {
        meeting_id: this.$route.query.meeting_id,
        page: 1,
        keyword: '',
        form_id: ''
      }
    };
  },
  created() {
    document.title = '签到列表';
    console.log(this.$route.query.meeting_id);
    this.formMarkFn();
  },
  methods: {
    moment,
    getExcel() {
      return window.location.href = window.location.origin + '/apis/setmeet/meeting/form/export/excel?form_id=' + this.searchData.form_id;
      // //模拟a链接下载模板
      // let a = document.createElement("a");
      // a.href =   'http://192.168.3.56:9520/apis/setmeet/meeting/task/export/absence/specialist'
      // a.download = "template.xlsx";
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a); 
    },
    clearDataAll() {
      this.$message.error('暂未开放！')
    },
    async formMarkFn() {
      let data = await formMark({ meeting_id: this.$route.query.meeting_id })
      this.formMarkList = data.payload.data;
      console.log(this.formMarkList);
      if (this.formMarkList[0]) {
        this.chanegColumns(0)
        this.searchData.form_id = this.formMarkList[0].id;
        this.getList();
      }
    },
    chanegColumns(index) {
      let baseList = []
      baseList.push({
        title: "提交时间",
        key: "send_time",
        width: 150,
        scopedSlots: { customRender: "send_time" },
        align: "left",
      },)
      console.log(this.formMarkList[index].form_content)
      this.formMarkList[index].form_content.forEach((item, index) => {
        if (item.label == 'Article') {
          item.label = '文章'
        }
        baseList.push({
          title: item.label,
          dataIndex: "laber_" + index,
          scopedSlots: { customRender: "laber_" + index, },
          key: "laber_" + index,
          align: "left",
        })
      })
      baseList.push({
        title: "操作",
        key: "action",
        fixed: 'right',
        scopedSlots: { customRender: "action" },
        align: "left",
      },)
      this.columns = baseList;
      console.log(baseList)
    },
    changeLogin(id) {
      this.formMarkList.forEach((item, index) => {
        if (item.id == id) {
          this.chanegColumns(index)
        }
      })
      this.searchData.keyword = '';
      this.searchData.page = 1;
      this.getList();
    },
    onSearch(str) {
      this.searchData.keyword = str;
      this.searchData.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let data = await formSignin(this.searchData);
      this.data = data.payload.data;
      this.ipagination.total = data.payload.page.total;
      this.loading = false;
    },
    change(data) {
      this.$refs.loginForm.show(data)
    }, //表格改变时触发`
    handlePageChange(page) {
      this.ipagination = page;
      this.searchData.page = page.current;
      this.getList();
    },
    delate(data) {
      var _this = this;
      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let str = await deleteSignin({ id: data.id })
          _this.$message.success('删除成功！')
          _this.getList();
        },
        onCancel() { },
      });
    },
    goUrl(data) {
      this.$router.push(
        "/workPlace?view_id=" +
        data.id +
        "&meeting_id=" +
        this.$route.query.meeting_id
      );
    },
    addPage() { this.$refs.page_modal.showModal() },
  },
};
</script>

<style lang="less" scoped>
.noJG {
  display: block;
  width: 200px; /* 设置一个固定的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noJG:last-child {
  span {
    display: none;
  }
}
.loginList_title {
  width: 500px;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  button {
    margin-left: 10px;
  }
}
.loginList_title_select {
  width: 362px;
  display: flex;
  margin-right: 16px;
  .loginList_title_T {
    width: 80px;
    height: 36px;
    background: #f5f7fa;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e5e6eb;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select {
    flex: 1;
  }
  :deep(.ant-select-selection) {
    border-radius: 0px 4px 4px 0px;
  }
}

.onSearchBox {
  width: 312px;
}
:deep(.onSearchBox .ant-btn-primary) {
  background: #f5f7fa;
  border: 1px solid #e5e6eb;
  color: #7e8081;
  box-shadow: unset;
}

.cloudBooth {
  padding: 24px;
}
</style>
<style>
.dataBox .dataBox_item:last-child .ant-divider {
  display: none;
}
</style>