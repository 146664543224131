<template>

  <div v-if="showModal" class="add add_pof">
    <div class="add_form add_pof">
      <div class="add_form_title">
        表单详情
        <a-icon @click="show" class="add_form_close" type="close" />
      </div>

      <div class="add_formBox">

        <div v-for="(item,index) in  form.sign_in_data_detail" :key="index" class="add_formBox_item">
          <div class="add_formBox_name"><span v-show="item.require" class="add_formBox_require">*</span>{{item.label}}：
          </div>
          <div class="add_formBox_inp">

            <span v-if="item.type=='checkBoxWork'">
              <span class="noJG" v-for="(item_a,index_a) in item.value" :key="index_a">{{item_a}} <span>,</span></span>
            </span>

            <span v-if="item.type=='timePickerWork'">
              {{Date.now(item.value)/1000|time('YYYY-MM-DD HH:mm')}}
            </span>

            <span class="text2" v-if="item.type=='articleWork'" v-html="item.value">
            </span>

            <span v-if="item.type=='cityWork'">
              <span class="noJG" v-for="(item_a,index_a) in item.value" :key="index_a">{{item_a}} <span>,</span></span>
            </span>

            <span v-if="item.type=='selectWork'">
              {{item?item.value:'未填写'}}
            </span>

            <span v-if="item.type=='inputWork'">
              {{item?item.value:'未填写'}}
            </span>

            <span v-if="item.type=='textareaWork'">
              {{item?item.value:'未填写'}}
            </span>

            <span v-if="item.type=='radioWork'">
              {{item?item.value:'未填写'}}
            </span>

            <span v-if="item.type=='upload_fileWork'">
              <span v-for="(item_a,index_a) in item.value" class="noJG" :key="index_a">
                <a :href="item_a.file_url" target="_blank">{{item_a.file_url}}</a>
                <span>,</span>
              </span>
            </span>

          </div>
        </div>

      </div>

      <div class="add_formBox_sendBox">
        <a-button @click="show" class="add_formBox_sendBox_qx">取消</a-button>
        <a-button @click="show" type="primary">
          保存
        </a-button>
      </div>

    </div>
  </div>
</template>

<script> 
export default {
  props: ['formwork'],
  data() {
    return {
      showModal: false,
      form: {}
    }
  },
  created() {
  },
  methods: {
    show(data) {
      this.form = data
      this.showModal = !this.showModal
      this.$forceUpdate();
    },
  },

}
</script>

<style lang="less" scoped>
.noJG {
  display: block;
  width: 200px; /* 设置一个固定的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.noJG:last-child {
  span {
    display: none;
  }
}
.login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: #00000073;
  filter: alpha(opacity=50);
}

.form {
  width: 70%;
  max-width: 400px;
  max-height: 70%;
  margin: auto;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  top: 20%;
}
.form_title {
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.form_data_list {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.laber {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.data {
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.form_item {
  flex: 1;
  overflow: auto;
}
.qd {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  width: 100%;
  background-color: #4988fd;
  border-radius: 24px;
  text-align: center;
  color: white;
  margin-top: 5px;
}
.close {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
}

.add_pof {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
}
.add {
  background-color: rgba(0, 0, 0, 0.6);
}
.add_form {
  width: 500px;
  min-height: 50px;
  max-height: 60vh;
  border-radius: 8px;
  background-color: #fff;
  overflow: auto;
  display: block;
  flex-direction: column;
  transform: translate(0%, -20%);
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  height: unset;
}
.add_form_title {
  // display: flex;
  justify-content: space-between;
  height: 46px !important;
  background: #f3f6f9;
  padding: 0 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);
  .add_form_close {
    color: #818181;
    cursor: pointer;
    display: inline-block;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;
  }
}
.add_formBox {
  flex: 1;
  padding: 20px;
  padding-bottom: 0;
}
.add_formBox_name {
  position: relative;
  // width: 6em;
  margin-right: 12px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 13px;
  padding-left: 0.7em;
  color: #222222;
}
.add_formBox_require {
  color: red;
  left: 0;
  margin-right: 5px;
  position: absolute;
}
.add_formBox_item {
  margin-bottom: 20px;
  display: flex;
}
.add_formBox_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.add_formBox_radio {
  position: relative;
  right: -16px;
}
.add_formBox_sendBox {
  // flex: 1;
  height: 56px;
  border-top: 1px solid #e3e4e8;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
}
.add_formBox_sendBox_qx {
  margin-right: 10px;
}
.add_formBox_time {
  width: 100%;
}
.add_formBox_inp {
  position: relative;
  word-break: break-all;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #222222;
  flex: 1;
  .iconfont {
    position: absolute;
    color: #d9d9d9;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
}

.add_form_close:hover {
  color: #000;
  font-weight: 600;
}
</style>

<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 38px;
}
.add_formBox_time .anticon {
  display: none;
}
.login .ant-form-item-control-wrapper {
  padding-bottom: 8px;
}
.login .ant-form-horizontal {
  margin-bottom: 8px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
.form_item_zj {
  margin-bottom: 12px;
}

.title_login {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
}
</style>