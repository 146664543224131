<template>
  <a-tooltip class="pointer">
    <template slot="title">
      {{txt}}
    </template>
    <img class="tooltip_img" width="12px" src="@/assets/auto_img/information-circle@2x (1).png" alt="">
  </a-tooltip>
</template>

<script>
export default {
  props: {
    txt: {
      type: String,
      default: '默认文本'
    }
  }
}
</script>

<style>
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #7f7f7f;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: #fff;
  content: "";
  pointer-events: auto;
}
</style>