<template>
  <div class="cloudBooth">
    <a-table :loading="loading" :pagination="ipagination" @change="handlePageChange" :rowKey="record=>record.id"
      :columns="columns" :data-source="data">

      <span slot="begin_time" slot-scope="text, record">
        <span>{{ record.begin_time?record.begin_time: '未填' }} </span>

      </span>

      <span slot="created_at" slot-scope="text, record">
        <span v-if="record.created_at">{{ record.created_at }}</span>
        <span v-if="!record.created_at">未填</span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click='change(record)'> 编辑 </a>
        <a-divider type="vertical" />
        <a @click="delate(record)" style="color:red"> 删除 </a>
      </span>
    </a-table>
  </div>
</template>

<script>
// import { getVideoList } from '@/api/meetApi.js';

import { geiVideoListAll, getVideoList, delateViewData, DelateVideoModular } from "@/api/meetApi";
const columns = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    align: "center",
  },
  {
    title: "名称",
    dataIndex: "title",
    key: "title",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "created_at",
    scopedSlots: { customRender: "created_at" },
    key: "created_at",
    align: "center",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  data() {
    return {
      current: ['page'],
      loading: false,
      data: [],
      columns,
      //分页参数
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  created() {
    console.log(this.$route.query.meeting_id);
    this.getList({ type: 1, meeting_id: this.$route.query.meeting_id });
  },
  methods: {
    async getList(page) {
      this.loading = true;
      let data = await getVideoList(page);
      this.data = data.payload.data;
      this.ipagination.total = data.payload.page.total;
      this.loading = false;
    },
    change(data) {
      // this.$refs.page_modal.change(data);
      this.$emit("changeBoth", data);
    }, //表格改变时触发`
    handlePageChange(page) {
      this.ipagination = page;
      this.getList({ type: 1, page: page.current, meeting_id: this.$route.query.meeting_id });
    },
    delate(data) { 
      var _this = this;
      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let str = await DelateVideoModular({ id: data.id })
          _this.$message.success('删除成功！')
          _this.getList({ type: 1,meeting_id: _this.$route.query.meeting_id });
        },
        onCancel() { },
      });
    },
    goUrl(data) {
      this.$router.push(
        "/workPlace?view_id=" +
        data.id +
        "&meeting_id=" +
        this.$route.query.meeting_id
      );
    },
    addPage() { this.$refs.page_modal.showModal() },
  },
};
</script>


<style>
</style>