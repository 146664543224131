<template>
  <div class="doctorChange">
    <a-drawer :destroyOnClose='true' class="doctorChangeForm" :width="500" placement="right" :closable='false'
      :maskClosable='false' :visible="visible" :after-visible-change="afterVisibleChange" @close="onClose">
      <div class="DC_itle">
        <span v-if="!form.specialist_task_relation_id">新增任务</span>
        <span v-if="form.specialist_task_relation_id">编辑任务</span>
        <img @click='onClose' class="pointer" width="16px" src="@/assets/auto_img/icon_关闭@2x.png" alt="">
      </div>

      <div class="DC__form">

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: meetData.meeting_name}"
              class="add_formBox_require">*</span>所属门户</div>
          <div class="add_formBox_inp">
            <a-input disabled v-model="meetData.meeting_name" placeholder="" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.name}" class="add_formBox_require">*</span>姓名
          </div>
          <div class="add_formBox_inp add_por">

            <a-select ref="select_base" mode="SECRET_COMBOBOX_MODE_DO_NOT_USE" v-model="form.name"
              @change="docNameChange" style="width: 100%" placeholder="请输入或者选择">
              <a-select-option v-for="i in doctor_list" :key="i.name" :value='i.name'>
                {{i.name}}
              </a-select-option>
            </a-select>

          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.task_date}" class="add_formBox_require">*</span>日期
          </div>
          <div class="add_formBox_inp">
            <span class="date_icon"><img src="@/assets/auto_img/icon_日期@2x.png" alt=""></span>
            <a-date-picker class="add_formBox_time" format="YYYY-MM-DD" valueFormat='YYYY-MM-DD' placeholder="开始时间"
              v-model="form.task_date" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.begin_time}"
              class="add_formBox_require">*</span>时间</div>
          <div class="add_formBox_inp date_flex">
            <a-time-picker v-model="form.begin_time" :default-value="moment(form.begin_time, 'HH:mm')"
              valueFormat='HH:mm' format="HH:mm" />
            <span class="zhi">至</span>
            <a-time-picker v-model="form.end_time" :default-value="moment(form.end_time, 'HH:mm')" valueFormat='HH:mm'
              format="HH:mm" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.identity}" class="add_formBox_require">*</span>身份
          </div>
          <div class="add_formBox_inp">
            <a-select placeholder="请选择" v-model="form.identity" style="width:100%">
              <a-select-option v-for="(item,index) in identityList" :key="index" :value="item.identity">
                {{item.title}}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.live_title}"
              class="add_formBox_require">*</span>直播
          </div>
          <div class="add_formBox_inp">
            <a-select placeholder="请选择" v-model="form.live_id" @change='videoChange' style="width:100%">
              <a-select-option v-for="(item,index) in videoList" :key="index" :value="item.id">
                {{item.title}}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.module_title}"
              class="add_formBox_require">*</span>环节
          </div>
          <div class="add_formBox_inp">
            <a-input v-model="form.module_title" placeholder="请输入" />
          </div>
        </div>

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span :class="{required: form.task_title}"
              class="add_formBox_require">*</span>讲题</div>
          <div class="add_formBox_inp">
            <a-input v-model="form.task_title" type='textarea' :auto-size="{ minRows: 4, maxRows: 6 }" placeholder="请输入"
              :maxLength='100' :suffix="form.task_title.length+'/100'" />
          </div>
        </div>

      </div>
      <div class="dc_btn">
        <a-button @click="onClose">取消</a-button>
        <a-button @click="sendForm" type="primary">
          确定
        </a-button>
      </div>

    </a-drawer>
  </div>
</template>
<script>
import moment from "moment";
import { geiVideoListAll, getTaskDetail, getTaskModule, createTask, getSpecialistList } from '@/api/meetApi';
export default {
  props: ['identityList', 'meetData'],
  data() {
    return {
      videoList: [],
      doctor_list: [],
      form: {
        meeting_id: this.$route.query.meeting_id,
        name: '',
        identity: '',
        live_title: '',
        task_title: '',
        task_date: '',
        begin_time: '00:00',
        end_time: '00:00',
        module_title: '',
      },
      meeting_data: {},
      TaskModule: [],
      visible: false,
    };
  },
  created() {
    this.getTaskModuleFN()
    this.getSpecialistListFN()
    this.getVideList()
  },
  methods: {
    moment,
    videoChange(e) {
      this.videoList.forEach(item => {
        if (item.id == e) {
          return this.form.live_title = item.title
        }
      })
    },
    async getVideList() {
      let data = await geiVideoListAll({
        meeting_id: this.$route.query.meeting_id,
        per_page: 999,
      })
      this.videoList = data.payload.data
    },
    docNameChange(e) {
      console.log(this.form)
      let _this = this
      this.doctor_list.forEach(i => {
        if (i.name == e) {
          _this.form.specialist_id = i.id
          console.log(_this.form)
          return
        }
      });
    },
    select_baseChange(e) {
      this.name_and_hospital = e.target.value
      console.log(e.target.value);
      this.$refs['select_base'].showSearch = true;

    },
    async getSpecialistListFN() {
      let data = await getSpecialistList({ page: 1, per_page: 999999, keyword: '' })
      this.doctor_list = data.payload.data;
      //判断this.doctor_list中是否有同名的，给同名的加上id
      this.doctor_list.forEach((item, index) => {
        if (this.doctor_list.filter(i => i.name.includes(item.name)).length > 1) {
          this.doctor_list[index].name = item.name + '-' + item.hospital_name + '-' + item.id;
        }
      })
      console.log(this.doctor_list);
    },
    async sendForm() {
      console.log(this.form);
      for (const key in this.form) {
        if (!this.form[key]) {
          return this.$message.error('请全部填写完整！');
        }
      }
      let data = await createTask(this.form)
      this.$emit('getTaskList')
      this.onClose()
      if (this.form.specialist_task_relation_id) {
        return this.$message.success('修改成功！');
      }
      this.$message.success('创建成功！');

    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    async getTaskModuleFN() {
      let data = await getTaskModule({ meeting_id: this.form.meeting_id })
      this.TaskModule = data.payload.data;
      console.log(data);
    },
    async showDrawer(id) {
      if (id) {
        let data = await getTaskDetail({ id: id })
        this.form = data.payload.data;
        this.form.name_and_hospital = this.form.name + '-' + this.form.specialist_id;
        this.meeting_data = data.payload.meeting_data;
        console.log(data);
      }
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.clearFN()
    },
    clearFN() {
      this.meeting_data = {}
      for (const key in this.form) {
        this.form[key] = ''
      }
    }
  },
};
</script>
<style lang="less" scoped>
.add_por {
  position: relative;
}
.add_por_poa {
  position: absolute;
  width: 100%;
  height: 100%;
  // pointer-events: none;
  z-index: 0;
  left: 0;
}
.add_por_poa:focus {
  .ant-select-dropdown {
    display: block;
  }
}
.dc_btn {
  width: 100%;
  position: absolute;
  border-top: 1px solid #e3e4e8;
  height: 64px;
  bottom: 0;
  button {
    margin-left: 12px;
  }
  padding-right: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: right;
  background: #ffffff;
}
.date_flex {
  display: flex;
  align-items: center;
  .zhi {
    font-weight: 500;
    font-size: 14px;
    color: #222222;
    margin: 0 12px;
  }
}
.date_icon {
  img {
    position: absolute;
    color: #d9d9d9;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
    width: 12px;
  }
}
.DC_itle {
  height: 46px;
  background: #f9fafd;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: #222222;
  line-height: 20px;
  text-align: left;
}
.DC__form {
  padding: 20px;
  flex: 1;
  padding-bottom: 70px;
}
.required {
  color: green;
}
</style>
<style>
.ant-drawer-body {
  padding: 0;
}
</style>

<style>
.date_flex .ant-time-picker {
  flex: 1;
}
.date_flex .ant-time-picker-icon {
  left: 11px;
  right: unset;
}
.date_flex .ant-time-picker-input {
  padding-left: 38px;
}
.date_flex .ant-time-picker-clear {
  left: 11px;
  right: unset;
}
.doctorChangeForm {
}
.doctorChangeForm .ant-input-search-button,
.doctorChangeForm .ant-select-selection--single,
.doctorChangeForm .ant-input {
  height: 36px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-selection__rendered {
  line-height: 36px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
  font-weight: 400;
  font-size: 14px;
}

.doctorChangeForm .ant-select-lg .ant-select-selection--single,
.doctorChangeForm .ant-input-lg {
  height: 40px;
  font-weight: 400;
  font-size: 14px;
}
.doctorChangeForm .ant-btn {
  height: 36px;
}
/* .doctorChangeForm .ant-select-search__field__wrap {
  opacity: 0;
} */
</style>