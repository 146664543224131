<template>
  <div v-if="ruleForm.id" class="meetForm">

    <div class="meetForm_item">
      <div class="meetForm_item_leb"><span class="requireED">*</span>门户名称：</div>
      <div class="meetForm_item_box">
        <a-input class="w480 suf_pr72" placeholder="门户名称" v-model="ruleForm.meeting_name" type="text" :maxLength='100'
          :suffix="ruleForm.meeting_name.length+'/100'" />
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb"><span class="requireED">*</span>开始时间：</div>
      <div class="meetForm_item_box flex_box">
        <div class="add_formBox_inp w480">
          <span class="date_icon"><img src="@/assets/auto_img/icon_日期@2x.png" alt=""></span>
          <a-date-picker class="add_formBox_time" format="YYYY.MM.DD HH:mm:ss" valueFormat="YYYY.MM.DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间"
            v-model="ruleForm.begin_time" />
        </div>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb"><span class="requireED">*</span>结束时间：</div>
      <div class="meetForm_item_box flex_box">
        <div class="add_formBox_inp w480">
          <span class="date_icon"><img src="@/assets/auto_img/icon_日期@2x.png" alt=""></span>
          <a-date-picker class="add_formBox_time" format="YYYY.MM.DD HH:mm:ss" valueFormat="YYYY.MM.DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" placeholder="开始时间"
            v-model="ruleForm.end_time" />
        </div>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">全局背景图：<tooltip class="MT_tooltip" :txt='txt1'></tooltip>
      </div>
      <div class="meetForm_item_box w280 h158">
        <upload :action="'建议图片大小不超过3MB'" class="meetForm_item_boxUPIMG" :image='ruleForm.background_img_path'
          @uploadOk='uploadOk' :showDelate='true' @delateDate='delateDate' :height="'138px'">
        </upload>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">全局背景色：<tooltip class="MT_tooltip" :txt='txt2'></tooltip>
      </div>
      <div class="meetForm_item_box color_css">
        <color_picker id='all_bg' key="all_bg" v-model="ruleForm.background_color"></color_picker>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">视频占位图：<tooltip class="MT_tooltip" :txt='txt3'></tooltip>
      </div>
      <div class="meetForm_item_box kv_css">
        <upload class="meetForm_item_boxUPIMG" :action="'建议长宽比为16:9'" :image='ruleForm.video_img_path'
          @delateDate='delateDate2' :attention='true' :showDelate='true' @uploadOk='uploadOk2' :height="'138px'">
        </upload>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">全局底部背景图：<tooltip class="MT_tooltip" :txt='txt4'></tooltip>
      </div>
      <div class="meetForm_item_box w280">
        <upload class="meetForm_item_boxUPIMG" :image='ruleForm.tail_img_path' @uploadOk='uploadOk3' :height="'70px'"
          :showDelate='true' @delateDate='delateDate3'></upload>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">自定义返回菜单：
      </div>
      <div class="meetForm_item_box">
        <a-switch key="is_back_menu" id="is_back_menu" size="small" :default-checked='ruleForm.is_back_menu==1'
          @change="TABonChange" />
      </div>
    </div>

    <div v-if="ruleForm.is_back_menu==1" class="meetForm_item">
      <div class="meetForm_item_leb">返回菜单位置：
      </div>
      <div class="meetForm_item_box">
        <a-radio-group name="radioGroup" v-model="ruleForm.back_menu_type" :default-value="1">
          <a-radio :value="1">
            右下
          </a-radio>
          <a-radio :value="2">
            顶部
          </a-radio>
        </a-radio-group>
      </div>
    </div>

    <div v-show="ruleForm.is_back_menu==1&&ruleForm.back_menu_type==2" class="meetForm_item">
      <div class="meetForm_item_leb">菜单背景颜色：
      </div>
      <div class="meetForm_item_box">
        <color_picker id='btn_bg' key="btn_bg" v-model="ruleForm.back_menu_bgcolor"></color_picker>
      </div>
    </div>

    <!-- <div class="meetForm_item">
      <div class="meetForm_item_leb">分享图：</div>
      <div class="meetForm_item_box w80">
        <upload :image='ruleForm.sharing_img_path' @uploadOk='uploadOk4' :width="'80px'" :showDelate='true'
          :height="'80px'"></upload>
      </div>
    </div>

    <div class="meetForm_item">
      <div class="meetForm_item_leb">大会简介：</div>
      <div class="meetForm_item_box">
        <a-textarea class="w480" v-model="ruleForm.content" placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </div>
    </div> -->
    
    <div class="save">
      <a-button @click="handleOk" type="primary">
        保存
      </a-button>
    </div>

  </div>
</template>

<script> 
import moment from "moment";
import upload from '@/components/upload.vue';
import color_picker from '@/components/color_picker.vue';
import { setMeetList, setViewList, meetingDetail } from "@/api/meetApi";
import tooltip from "@/components/tooltip.vue";
export default {
  components: { upload, color_picker, tooltip },
  data() {
    return {
      txt1: '页面背景图片，当页面宽度小于768px时，背景图片默认宽度100%，高度自适应（最低高度为100%，页面高度超出100&时，图片会拉伸）。当页面宽度大于等于768px时，背景图片宽度768px，高度自适应，背景图片以外的部分为全局背景色。',
      txt2: '全局背景色，可与全局背景图一起使用，也可以单独设置。全局背景图显示在全局背景色的上方。',
      txt3: '门户里视频底部kv。',
      txt4: '页面底部图片，宽度100%，高度自适应。显示在全局背景图的上方。',
      inp_color: '',
      windLink: window.location.origin + '/meeting/',
      visible: false,
      confirmLoading: false,
      ruleForm: {
        "id": "",
        "meeting_name": "",
        "begin_time": "",
        "end_time": "",
        "created_at": "",
        "updated_at": "",
        "content": "",
        "address": "",
        "video_img": "",
        "video_img_path": "",
        "background_img": "",
        "background_img_path": "",
        "background_color": "#ffffff",
        "tail_img": "",
        "tail_img_path": "",
        "custom_link": '',
        'is_back_menu': 2,
        'back_menu_type': 2,
        'back_menu_bgcolor': "#ffffff",
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    };
  },
  created() {
    this.getMeetData()
  },
  methods: {
    moment,
    async getMeetData() {
      let data = await meetingDetail({ id: this.$route.query.meeting_id });
      this.ruleForm = data.payload.meeting_data;
      this.SET_WEB_ROUTER(['门户列表', this.ruleForm.meeting_name])
      console.log(data)
    },
    TABonChange(data) {
      if (data) {
        this.ruleForm.is_back_menu = 1
      }
      else {
        this.ruleForm.is_back_menu = 2 
      }
      this.$forceUpdate()
      console.log(this.ruleForm.is_back_menu)
    },
    uploadOk(data) {
      this.ruleForm.background_img = data.payload.id
      this.ruleForm.background_img_path = data.payload.path
      console.log(data)
    },
    uploadOk2(data) {
      this.ruleForm.video_img = data.payload.id
      this.ruleForm.video_img_path = data.payload.path
      console.log(data)
    },
    delateDate2() {
      this.ruleForm.video_img = ''
      this.ruleForm.video_img_path = ''
    },
    uploadOk3(data) {
      this.ruleForm.tail_img = data.payload.id
      this.ruleForm.tail_img_path = data.payload.path
      console.log(data)
    },
    delateDate3() {
      this.ruleForm.tail_img = ''
      this.ruleForm.tail_img_path = ''
    },
    uploadOk4(data) {
      this.ruleForm.sharing_img = data.payload.id
      this.ruleForm.sharing_img_path = data.payload.path
      console.log(data)
    },
    delateDate4() {
      this.ruleForm.sharing_img = ''
      this.ruleForm.sharing_img_path = ''
    },
    async change(data) {
      await this.clear();
      this.ruleForm = JSON.parse(JSON.stringify(data));
      console.log(this.ruleForm)
      this.showModal();
    },
    showModal() {
      this.visible = true;
    },
    async handleOk(e) {
      if (!this.ruleForm.meeting_name) {
        return this.$message.error("名称必填！");
      }

      this.confirmLoading = true;
      try {
        let data = await setMeetList(this.ruleForm);
        this.$emit("getList", data);
        // this.clear();
        this.visible = false;
        this.confirmLoading = false;
        return this.$message.success("创建成功！");
      } catch {
        this.confirmLoading = false;
      }
    },
    color_change() {
      this.inp_color = this.ruleForm.background_color.slice(1, 9)
    },
    color_change_inp() {
      if (this.inp_color.length == 3 || this.inp_color.length == 6) {
        this.ruleForm.background_color = '#' + this.inp_color
      } else {
        this.$message.error("请输入正确的颜色！")
      }
    },
    chongzhi() {
      this.inp_color = ''
      this.ruleForm.background_color = ''
    },
    handleCancel(e) {
      var _this = this;
      for (const key in this.ruleForm) {
        if (this.ruleForm[key]) {
          return this.$confirm({
            title: "确定退出吗！",
            content: "退出不会保留修改内容！",
            okText: "确定",
            okType: "danger",
            cancelText: "取消",
            onOk() {
              _this.visible = false;
              _this.clear();
            },
            onCancel() { },
          });
        }
      }
      this.visible = false;
    },
    clear() {
      for (const key in this.ruleForm) {
        this.ruleForm[key] = "";
      }
    },
    delateDate(data) {
      this.ruleForm.background_img = ''
      this.ruleForm.background_img_path = ''
      console.log(3456)
    },
    sliderOninput(val) {
      let rgb = this.hexToRgb(this.ruleForm.background_color);
      this.ruleForm.textBgc = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + val + ")"
      this.sliderOninputNUM()
      console.log(this.ruleForm.textBgc)
    },
    sliderOninputNUM() {
      let num = this.ruleForm.background_color.slice(-4, -1)
      console.log(num)
      return this.ruleForm.background_color
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
};
</script>

<style lang="less" scoped>
.MT_tooltip {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-40%);
}
.meetForm {
  margin: 12px 0 0 0px;
  background-color: #fff;
  padding-top: 20px;
  position: relative;
  padding: 0 24px;
  padding-bottom: 75px;
}
.meetForm_item {
  display: flex;
  margin-bottom: 24px;
}
.meetForm_item_leb {
  width: 150px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  padding-right: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.requireED {
  position: absolute;
  left: 24px;
  font-size: 14px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  top: 6px;
  color: red;
}
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  span {
    font-size: 24px;
    font-weight: 500px;
  }
}
:deep(.ant-calendar-picker) {
  width: 100%;
}
.w130 {
  width: 130px;
}
.inp_color {
  height: 100%;
  margin-right: 10px;
}
.home_base {
  display: flex;
  position: relative;
  top: 5px;
  .home_base_l {
    flex: 1;
  }
  .home_base_r {
    flex: 1;
  }
}
.windLink {
  border: 0px;
  box-shadow: unset;
}
.add_formBox_inp {
  width: 220px;
  position: relative;
  .date_icon {
    img {
      position: absolute;
      color: #d9d9d9;
      z-index: 1;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 12px;
      width: 12px;
    }
  }
}
.w480 {
  width: 480px;
}
.flex_box {
  display: flex;
  width: 480px;
  .zhi {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }
}
.save {
  width: calc(100% - 204px);
  position: fixed;
  bottom: 12px;
  left: 192px;
  background-color: #fff;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.05);
  // padding-right: 235px;
}
.kv_css {
  width: 280px;
  height: 158px;
  position: relative;
}
.kv_at {
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #585858;
  line-height: 17px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  bottom: 30px;
}
.w280 {
  width: 280px;
  height: 80px;
}
.h158 {
  height: 158px;
}
.w80 {
  width: 80px;
  height: 80px;
}
.color_css {
  display: flex;
  width: 480px;
}
.w36 {
  width: 32px;
}
.nopadding {
  padding: 0 !important;
  margin-right: 0;
}
.inp_color_box {
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    pointer-events: none;
    z-index: 3;
    cursor: pointer;
  }
}
</style> 
 
<style>
.add_formBox_time .ant-calendar-picker-input {
  padding-left: 32px;
}
.add_formBox_time .anticon {
  display: none;
}
input[type="color" i]::-webkit-color-swatch-wrapper {
  padding: 0;
}
.w80 .changeImg {
  font-size: 12px !important;
}
.w80 .changeImg i {
  font-size: 12px !important;
}

.meetForm_item_boxUPIMG .ant-upload {
  width: 280px;
  height: 100%;
}
</style>