<template>
  <div class="videoList_MH">
    <div class="page_menu_item p24">
      <a-button @click="addVideo" type="primary"> 新增图文 </a-button>
      <div style="flex:1"></div>
      <a-input-search v-model="keyword" class="onSearchBox" placeholder="请输入关键字" enter-button="搜索" @search="onSearch" />
    </div>
    <a-table class="p24" :loading="loading" @change="video_handleTableChange" :pagination="video_pagination"
      :rowKey='record=>record.id' :columns="video_columns" :data-source="video_data">

      <span class="video_title" slot="video_title" slot-scope="text, record">
        <span class="menu_name">{{record.title}}</span>
      </span>

      <span slot="publish_time" slot-scope="text, record">
        <span v-if="!record.publish_time">未填</span>
        <span v-if="record.publish_time">{{ record.publish_time|time('YYYY.MM.DD') }} </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click='video_workPlace(record)'> 配置 </a>
        <a-divider type="vertical" />
        <a @click='shareVideoFn(record)'> 推广 </a>
        <a-divider type="vertical" />
        <a @click="video_delate(text)"> 删除 </a>
      </span>
    </a-table>
    <video_Modal ref="video_modal" class="video_modal" @addList='getVideList' />
    <Preview ref="preview" />

  </div>
</template>

<script>

const video_columns = [
  {
    title: "图文标题",
    dataIndex: "video_title",
    scopedSlots: { customRender: "video_title" },
    key: "video_title",
    // width: 350,
    align: "left",
  },
  {
    title: "发布时间",
    key: "publish_time",
    scopedSlots: { customRender: "publish_time" },
    align: "left", width: 350,
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left", width: 250,
  },
];
import video_Modal from "@/views/articleList/components/Modal.vue";
import Preview from "@/components/Preview.vue";

import { getMeetDocument, delateVideo, } from "@/api/meetApi";

export default {
  components: { video_Modal, Preview },
  data() {
    return {
      // 
      // 
      // 
      keyword: '',
      video_data: [],
      video_columns,
      video_pagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      }
    }
  },
  created() {
    this.getVideList()
  },
  methods: {
    video_addList(data) {
      this.getVideList()
    },
    video_change(data) {
      this.$refs.modal.change(data);
    },
    video_workPlace(data) {
      this.$router.push("/articleSet?id=" + data.id);
    },
    video_delate(msg) {
      var _this = this;

      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let data = await delateVideo({
            live_id: msg.id
          })
          _this.getVideList()
        },
        onCancel() { },
      });
    },
    async getVideList() {
      this.loading = true;

      let data = await getMeetDocument({
        meeting_id: this.$route.query.meeting_id,
        page: this.video_pagination.current,
        keyword: this.keyword
      })
      console.log(data)
      this.video_data = data.payload.data
      // this.video_pagination.current = data.payload.page.current_page
      this.video_pagination.total = data.payload.page.total
      this.loading = false;


    },
    onSearch() {
      this.video_pagination.current = 1
      this.getVideList()
    },
    video_handleTableChange(data) {
      this.video_pagination.current = data.current
      this.getVideList()
    },
    addVideo() {
      console.log(3456)
      this.$refs.video_modal.showModal()
    },
    shareVideoFn(data) {
      this.$refs.preview.show('document', data.id);
    },
  }
}
</script>

<style lang="less" scoped>
.videoList_MH {
  padding: 24px;
}
.page_menu_item {
  display: flex;
  padding-bottom: 20px;
  .onSearchBox {
    width: 280px;
  }
}
</style>