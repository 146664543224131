<template>
  <div v-if="visible" class="add add_pof">
    <div class="add_form add_pof">
      <div v-if="!visibleCheck" class="add_form_title">
        批量导入
        <a-icon @click="close" class="add_form_close" type="close" />
      </div>
      <div v-if="visibleCheck" class="add_form_title">
        信息核对
        <a-icon @click="close" class="add_form_close" type="close" />
      </div>

      <div class="add_formBox">

        <div class="add_formBox_item mb20">
          <div class="add_formBox_name"><span class="add_formBox_require">*</span>所属门户</div>
          <div class="add_formBox_inp">
            <a-input v-model="meetData.meeting_name" disabled placeholder="请输入" :maxLength='100'
              :suffix="meetData.meeting_name.length+'/100'" />
          </div>
        </div>

        <div class="add_formBox_item">
          <div class="add_formBox_name flexBox">
            <span><span class="add_formBox_require">*</span>开始时间</span>
            <a-popover class="doctorBox" placement="bottom">
              <template slot="content">
                <p>请按照模版格式完成表单内容</p>
              </template>
              <a-button @click="downloadFn" type="link"><img height="14px" src="@/assets/auto_img/icon_下载@2x.png"
                  alt=""> 下载模板</a-button>
            </a-popover>
          </div>
          <div class="add_formBox_inp upload_base">
            <a-upload-dragger :customRequest='uploadFn' list-type="picture-card" class="avatar-uploader"
              :show-upload-list="false" :before-upload="beforeUpload" name="file" :multiple="false"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76" @change="handleChange">
              <div v-if="!status">
                <p class="ant-upload-drag-icon">
                  <img width="40px" src="@/assets/auto_img/icon_添加@2x.png" alt="">
                </p>
                <p class="ant-upload-textA">
                  将文件拖到此处，或<span>点击上传</span>
                </p>
              </div>
              <div v-if="status">
                <p class="ant-upload-drag-icon">
                  <img width="40px" src="@/assets/auto_img/excel.png" alt="">
                </p>
                <p class="okBoxA">
                  <span>{{file.file.name}}</span>
                </p>
                <p class="okBox">
                  <span v-if="!file">
                    <img src="@/assets/auto_img/icon_成功@2x (1).png" alt="">
                    <span>上传失败</span>
                  </span>

                  <span v-if="file">
                    <img src="@/assets/auto_img/icon_成功@2x.png" alt="">
                    <span>上传成功</span>
                  </span>
                </p>
              </div>
            </a-upload-dragger>
          </div>
        </div>

        <div v-if="visibleCheck&&showCheck==1" class="allok">
          <img width="15px" src="@/assets/auto_img/icon_成功@2x.png" alt="">
          已核对{{checkSpeciaData.check_num}}条数据
        </div>
        <div v-if="visibleCheck&&showCheck==2" class="allnook">
          <div class="flexBoxA">
            <img width="15px" src="@/assets/auto_img/icon_成功@2x (1).png" alt="">
            已核对{{checkSpeciaData.check_num}}条数据
          </div>
          <div v-if="checkSpeciaData.same_name_data">重名：{{checkSpeciaData.same_name_data}}</div>
          <div v-if="checkSpeciaData.lack_data" style="display:block">信息缺失：{{checkSpeciaData.lack_data}} <span @click="downloadErrFN"
              class="allnookD pointer"><img width="13px" src="@/assets/icon_下载@2x (1).png" alt="">下载</span>
          </div>
        </div>

      </div>

      <div class="add_formBox_sendBox">
        <a-button @click="close" class="add_formBox_sendBox_qx">取消</a-button>
        <a-button v-if="!visibleCheck" @click="handleOk" type="primary">
          确定
        </a-button>
        <a-button v-if="visibleCheck" @click="close" type="primary">
          确定
        </a-button>
      </div>

    </div>
  </div>
</template>
<script>
import { uploadFn } from "@/api/upload";
import { uploadExcel, downloadTemplate, checkSpecialist, downloadErr } from '@/api/meetApi';
export default {
  props: ['meetData'],
  data() {
    return {
      checkSpeciaData: {},
      form: {
        title: ''
      },
      file: {},
      loading: false,
      visible: false,
      status: false,
      visibleCheck: false,
      showCheck: 0,
      file_id: ''
    };
  },
  methods: {
    async checkSpecialistFn() {
      let data = await checkSpecialist({ file_id: this.file_id, meeting_id: this.$route.query.meeting_id });
      this.showCheck = true
      this.checkSpeciaData = data.payload.data;
      if (this.checkSpeciaData.lack_data || this.checkSpeciaData.same_name_data) {
        this.showCheck = 2
      } else {
        this.showCheck = 1
      }
    },
    handleChange(info) {
      console.log(this.file)
      this.file = info;
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
      }
    },
    async downloadFn() {
      let data = await downloadTemplate();
      console.log(data.payload.file_url)
      //模拟a链接下载模板
      let a = document.createElement("a");
      a.href = data.payload.file_url;
      a.download = "template.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //模拟a链接下载模板
      // window.location.href = data.payload.file_url;
    },
    async downloadErrFN() {
      return window.location.href = window.location.origin + '/apis/setmeet/meeting/task/export/absence/specialist?lack_data=' + this.checkSpeciaData.lack_data + '&meeting_id=' + this.$route.query.meeting_id;
      let data = await downloadErr({ lack_data: this.checkSpeciaData.lack_data, meeting_id: this.$route.query.meeting_id });
      console.log(data.payload.file_url)
      // //模拟a链接下载模板
      // let a = document.createElement("a");
      // a.href =   'http://192.168.3.56:9520/apis/setmeet/meeting/task/export/absence/specialist'
      // a.download = "template.xlsx";
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a); 
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isJpgOrPng) {
        this.$message.error("请上传Excel文件！");
      }
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isLt2M) {
        this.$message.error("文件大小不超过10M！");
      }
      return isJpgOrPng && isLt2M;
    },
    async uploadFn(data) {
      try {
        let str = await uploadFn({ file: data.file });
        if (str.status == 1) {
          this.file_id = str.payload.id;
          this.file.file.status = "done";
          this.status = true
          this.handleChange(this.file);
          // this.$emit("uploadOk", str);
        } else {
          this.file = "";
          this.status = true
          this.$message.error("上传失败！");
        }

        console.log(this.file_id)
      } catch {
        this.file = "";
        this.status = true
        this.$message.error("上传失败！");
      }

      console.log(this.visibleCheck)
      if (this.visibleCheck) {
        this.checkSpecialistFn();
      }

    },
    close() {
      this.visible = false;
      this.status = false
      this.visibleCheck = false;
      this.showCheck = 0
      this.checkSpeciaData = {}
      this.form = {
        title: ''
      }
      this.file = {}
    },
    async handleOk() {
      if (!this.file_id) {
        return this.$message.error('请先上传文件！')
      }
      let data = await uploadExcel({ file_id: this.file_id, meeting_id: this.$route.query.meeting_id });
      if (data.status == 1) {
        this.$message.success('导入成功！')
        this.$emit("uploadOk");
        this.close();
      } else {
        this.$message.success('导入失败！请刷新重新导入！')
      }
    },
    showModal() {
      this.visibleCheck = false;
      this.visible = true;
    },
    showModalCheck() {
      this.visible = true;
      this.visibleCheck = true;
    },
  },
};
</script>
<style lang="less" scoped>
.allok {
  img {
    margin-right: 6px;
  }
  display: flex;
  line-height: 18px;
  align-items: center;
  padding: 10px;
  background: rgba(43, 184, 64, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(43, 184, 64, 0.5);
}
.flexBoxA {
  display: flex;
  img {
    margin-right: 6px;
  }
  line-height: 18px;
  align-items: center;
}
.allok {
  line-height: 18px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 18px;
  text-align: left;
}
.allnookD {
  width: 50px;
  // display: flex;
  align-items: center;
  margin-left: 6px;
  color: #86909d;
}
.allnook {
  div {
    display: flex;
    align-items: center;
  }
  margin-top: 10px;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  line-height: 18px;
  text-align: left;
  padding: 10px;
  background: rgba(235, 93, 68, 0.05);
  border-radius: 4px;
  border: 1px solid rgba(235, 93, 68, 0.6);
}
.add_formBox_item {
  margin-bottom: 0;
}
.add_formBox_sendBox {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 10px;
}
.okBoxA {
  font-weight: 400;
  font-size: 12px;
  color: #585858;
}
.okBox {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  margin-top: 24px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 14px;
    margin-right: 4px;
    // position: relative;
    // top: -1px;
  }
}
.doctorBox {
  padding: 0;
}
.doctorUpload {
  position: fixed;
}
.add_form {
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  height: unset;
  min-height: 480px;
}
.upload_base {
  height: 200px;
  .ant-upload {
    height: 200px;
  }
}
.ant-upload-textA {
  font-weight: 400;
  font-size: 12px;
  color: #222222;
  span {
    color: #1890ff;
  }
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    margin-right: 6px;
    position: relative;
    top: -1px;
  }
}
.add_formBox_sendBox {
  padding: 12px 20px;
}
</style>
<style>
.add_form .ant-upload-list {
  display: none;
}
/* .ant-popover-inner {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.ant-popover-inner-content {
  color: #fff;
}
.ant-popover-arrow { 
  border-color: rgba(0, 0, 0, 0.8) !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
} */
</style>
