<template>
  <div class="colorForm">
    <div class="color_inp">
      <a-input class="inp_color" @input="colorChange" v-model="hex8String" addon-before="#" placeholder="请输入色值" />
      <span @click="showColorFn" class="inp_color_box">
        <img v-if="!rgbaString" src="@/assets/auto_img/位图@2x.png" alt="">
        <span v-if="rgbaString" :style="'background-color:'+rgbaString" class="inp_color_text"></span>
      </span>
      <a-button @click="delType" class="inp_color_btn" type="link">
        重置
      </a-button>
    </div>

    <div v-show='showColor' class="color_home">
      <!-- 颜色插件官网https://iro.js.org/zh/guide.html -->
      <div class="colorBase">
        <div id="picker"></div>
        <div id="picker2"></div>
        <div id="picker3"></div>
        <div class="colorBase_menu">
          <div class="colorBase_menu_color">{{rgbaString}}</div>
          <div @click="delType" class="colorBase_menu_delate">清空</div>
          <div @click="okType" class="colorBase_menu_ok">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import iro from '@jaames/iro';

export default {
  data() {
    return {
      color:'',
      baseColor: "",
      rgbaString: '',
      hex8String: '',
      showColor: false,
      hex8String_preparation: ''
    }
  },
  mounted() {
    console.log(this.$attrs.value);
    this.getColor(this.$attrs.value)
  },
  methods: {
    getColor(color) {
      if(!color) color = '#ffffff'

      console.log(color)

      this.rgbaString = color
      this.baseColor = color
      this.color = color
      this.hex8String = this.baseColor.slice(1, 9)

      var colorPicker = new iro.ColorPicker('#picker', {
        width: 280,
        boxHeight: 180,
        color: this.color,
        layout: [
          {
            component: iro.ui.Box,
            options: {
            }
          },
        ]
      });


      var colorPicker2 = new iro.ColorPicker('#picker2', {
        width: 280,
        color: this.color,
        layout: [
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'alpha',
              sliderSize: 15,
            }
          },
        ]
      });



      var colorPicker3 = new iro.ColorPicker('#picker3', {
        width: 190,
        color: this.color,
        layoutDirection: 'horizontal',
        layout: [
          {
            component: iro.ui.Slider,
            options: {
              sliderType: 'hue',
              sliderSize: 15,
            }
          },
        ]
      });

      let _this = this
      colorPicker.on('color:change', function (color) {
        console.log(color);
        _this.rgbaString = color.rgbaString
        _this.hex8String_preparation = color.hex8String
        colorPicker2.color.rgba = color.rgba;
        colorPicker3.color.rgba = color.rgba;
      });
      colorPicker2.on('color:change', function (color) {
        _this.rgbaString = color.rgbaString
        _this.hex8String_preparation = color.hex8String
        console.log(color.rgba);
        colorPicker.color.rgba = color.rgba;
        colorPicker3.color.rgba = color.rgba;
      });
      colorPicker3.on('color:change', function (color) {
        _this.rgbaString = color.rgbaString
        _this.hex8String_preparation = color.hex8String
        console.log(color.rgba);
        colorPicker.color.rgba = color.rgba;
        colorPicker2.color.rgba = color.rgba;
      });
    },
    showColorFn() { 
      this.showColor = !this.showColor
    },
    delType() {
      this.baseColor = ""
      this.rgbaString = ''
      this.color = ''
      this.hex8String = ''
      this.showColor = false
      this.hex8String_preparation = ''
      this.$emit('input', '')
    },
    colorChange() {
      this.rgbaString = '#' + this.hex8String
      this.$emit('input', '#' + this.hex8String)
    },
    okType() {
      this.hex8String = this.hex8String_preparation.slice(1, 9)
      this.$emit('input', '#' + this.hex8String)
      this.showColor = false
    },
  }
};
</script>
<style lang="less" scoped>
.color_inp {
  display: flex;
  .inp_color {
    width: 122px;
    height: 36px;
    margin-right: 10px;
  }
  .inp_color_box {
    width: 36px;
    height: 36px;
    position: relative;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .inp_color {
      width: 36px;
      height: 36px;
      padding: 0;
    }
  }
  .inp_color_btn {
    height: 36px;
    padding: 0 10px;
  }
  .inp_color_text {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
}
</style>
<style >
.color_inp .ant-input-wrapper,
.color_inp .ant-input {
  height: 36px;
}
.color_home {
  position: absolute;
  top: 50px;
  right: -180px;
}

.colorBase {
  width: 314px;
  height: 248px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid #e5e6eb;
  padding: 6px;
  position: relative;
  z-index: 9;
}
#picker .IroBox {
  position: absolute;
  top: 0;
  border-radius: 2px !important;
}
#picker .IroColorPicker {
  position: absolute;
}
#picker2 {
  margin-top: 6px;
}
#picker2 .IroColorPicker {
  position: absolute;
  bottom: 42px;
}

.IroSliderGradient {
  border-radius: 1px !important;
}

#picker3 {
  position: absolute;
  right: 6px;
  top: 6px;
}
.colorBase_menu {
  position: absolute;
  left: 0;
  padding: 0 6px;
  bottom: 6px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.colorBase_menu_color {
  width: 143px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #e5e6eb;
  cursor: pointer;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #7f7f7f;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.colorBase_menu_delate {
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #1890ff;
  line-height: 17px;
  flex: 1;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
}
.colorBase_menu_ok {
  width: 56px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #e5e6eb;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #7f7f7f;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
circle {
  display: none;
}
circle:last-child {
  display: block;
}
.colorForm {
  position: relative;
}
</style>