<template>
  <div class="menu">
    <Modal ref="page_modal" class="page_modal" @getList='getList' />
    <Preview ref="preview" />

    <div class="page_menu">

      <a-menu @click="changeMenu" v-model="current" mode="horizontal">
        <a-menu-item key="form_base">基本 </a-menu-item>
        <a-menu-item key="page">页面 </a-menu-item>
        <a-menu-item key="video">直播</a-menu-item>
        <a-menu-item key="articleList">图文</a-menu-item>
        <a-menu-item key="loginList">表单数据</a-menu-item>
        <a-menu-item key="doctor">专家任务</a-menu-item>
        <a-menu-item key="addView">刷新</a-menu-item>
      </a-menu>

    </div>

    <div v-if="current&&current[0]=='page'" class="page_menu_item p24">
      <a-button @click="addPage" v-if="current&&current[0]=='page'" type="primary"> 新增页面
      </a-button>
    </div>

    <meetForm v-show="current&&current[0]=='form_base'"></meetForm>
    <a-table class="p24" v-if="current&&current[0]=='page'" :loading="loading" :pagination="ipagination"
      @change="handlePageChange" :rowKey="record=>record.id" :columns="columns" :data-source="data">

      <div style="max-width:400px" slot="titleBox" slot-scope="text, record">
        <div class="text2">{{record.title}}</div>
      </div>

      <span slot="type" slot-scope="text, record">
        <span class="page_type" v-if="record.type==1">主页</span>
        <span class="page_type" v-if="record.type!=1">内页</span>
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click='change(record)'> 设置 </a>
        <!-- <router-link to="/workPlace">详情</router-link> -->
        <a-divider type="vertical" />
        <a @click='goUrl(record)'> 装修 </a>
        <!-- <router-link to="/workPlace">详情</router-link> -->
        <a-divider type="vertical" />
        <a @click='shareFn(record)'> 推广 </a>
        <a-divider type="vertical" />
        <a @click="delate(record)"> 删除 </a>
      </span>
    </a-table>

    <videoList v-if="current&&current[0]=='video'"></videoList>
    <articleList v-if="current&&current[0]=='articleList'"></articleList>

    <cloudBooth ref="cloudBoothRef" @changeBoth='changeBoth' v-if="current&&current[0]=='cloudBooth'"></cloudBooth>

    <loginList ref="loginList" v-if="current&&current[0]=='loginList'"></loginList>

    <doctorBase v-if="current&&current[0]=='doctor'"></doctorBase>
    <addView v-if="current&&current[0]=='addView'"></addView>

  </div>
</template>
<script> 
import { geiVideoListAll, getViewList, delateViewData, delateVideo, exportExcel } from "@/api/meetApi";
import Modal from "./components/Modal.vue";
import cloudBooth from "./components/cloudBooth.vue";
import addCloudBooth from "./components/add_cloud_booth.vue";
import meetForm from './components/meetForm.vue'
import loginList from "./components/loginList.vue";
import videoList from "./components/videoList.vue";
import articleList from "./components/articleList.vue";
import doctorBase from "./components/doctorBase.vue";
import Preview from "@/components/Preview.vue";
import addView from "@/views/addView/index.vue";
const columns = [
  {
    title: "页面名称",
    dataIndex: "title",
    key: "title",
    scopedSlots: { customRender: "titleBox" },
    align: "left",
  },
  {
    title: "类型",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
    key: "type",
    align: "left",
  },
  {
    title: "页面内容",
    dataIndex: "content",
    key: "content",
    align: "left",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    align: "left",
  },
];

export default {
  components: { addView, articleList, videoList, Preview, meetForm, addCloudBooth, cloudBooth, Modal, loginList, doctorBase },
  data() {
    return {
      onSearchNum: '',
      current: ['form_base'],
      loading: false,
      data: [],
      columns,
      //分页参数
      ipagination: {
        size: "large",
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "30"], //可选的页面显示条数
        // onChange: page => handlePageChange(page),
        showTotal: (total, range) => {
          return "共 " + total + " 条";
        }, //展示每页第几条至第几条和总数
        hideOnSinglePage: false, // 只有一页时是否隐藏分页器 
        showQuickJumper: true, //是否可以快速跳转至某页
        showSizeChanger: true, //是否可以改变pageSize
      },
    };
  },
  created() {
    let pageItem = this.getSession('pageItem');
    if (pageItem) {
      this.current = [pageItem];
    } else {
      this.current = ['form_base']
    }
    console.log(this.current)
    this.getList({ meeting_id: this.$route.query.meeting_id });
  },
  methods: {
    onSearch() {
      this.$refs.loginList.onSearch(this.onSearchNum)
    },
    REoutPut() {
      this.onSearchNum = ''
      this.$refs.loginList.onSearch('')
    },
    async outPut() {
      // let data = await exportExcel() //导出接口未完成，暂注释
      this.$message.error('功能暂未开放！')
    },
    shareFn(data) {
      this.$refs.preview.show('view', data.id);
    },
    shareVideoFn(data) {
      this.$refs.preview.show('live', data.id);
    },
    changeMenu({ item, key, keyPath }) {
      this.setSession('pageItem', key)
      if (key == 'page') {
        this.getList({ meeting_id: this.$route.query.meeting_id });
      }
      if (key == 'video') {

      }
      if (key == 'doctor') { }
    },
    async getList(page) {
      this.loading = true;
      let data = await getViewList(page);
      this.data = data.payload.data;
      this.ipagination.total = data.payload.page.total;
      this.loading = false;
    },
    change(data) {
      this.$refs.page_modal.change(data);
    }, //表格改变时触发`
    handlePageChange(page) {
      this.ipagination = page;
      this.getList({ page: page.current, meeting_id: this.$route.query.meeting_id });
    },
    delate(data) {
      let _this = this;
      this.$confirm({
        title: "确认删除吗？",
        content: "删除后数据不可恢复！",
        okText: "确认",
        cancelText: "取消",
        async onOk() {
          let str = await delateViewData({ id: data.id })
          _this.$message.success('删除成功！')
          _this.getList({ meeting_id: _this.$route.query.meeting_id });
        },
        onCancel() { },
      });
    },
    goUrl(data) {
      this.$router.push(
        "/workPlace?view_id=" +
        data.id +
        "&meeting_id=" +
        this.$route.query.meeting_id
      );
    },
    addPage() { this.$refs.page_modal.showModal() },
    //  
    addCloudBooth() { this.$refs.addCloudBooth.showModal() },
    changeBoth(data) { this.$refs.addCloudBooth.change(data) },
    getListCloudBooth() {
      this.$refs.cloudBoothRef.getList({ page: 1, meeting_id: this.$route.query.meeting_id })
    }
  },
};
</script>

<style lang="less" scoped>
.menu {
  // max-width: 1400px;
  min-height: calc(100vh - 75px);
  margin: auto;
  position: relative;
  background-color: #fff;
  margin: 0 12px 12px 12px;
}
.p24 {
  padding: 0 24px;
}
.page_menu {
  display: flex;
  justify-content: space-between;
}

.item_list {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

:deep(.page_modal) {
  .title {
    display: none;
  }
}

:deep(.video_modal) {
  .title {
    display: none;
  }
}
.dian {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.dian1 {
  background-color: green;
}
.dian2 {
  background-color: red;
}
.dian3 {
  background-color: dimgrey;
}

.loginList_title {
  width: 500px;
  display: flex;
  width: 100%;
  button {
    margin-left: 10px;
  }
}
.loginList_title_select {
  width: 362px;
  display: flex;
  margin-right: 16px;
  .loginList_title_T {
    width: 80px;
    height: 36px;
    background: #f5f7fa;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #e5e6eb;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select {
    flex: 1;
  }
  :deep(.ant-select-selection) {
    border-radius: 0px 4px 4px 0px;
  }
}

.ant-menu-horizontal {
  width: 100%;
}
.page_menu_item {
  margin: 24px 0 20px 0;
}

:deep(.ant-table-thead) {
  height: 48px;
  tr {
    th {
      background: #f3f6f9;
      height: 48px;
      padding: 0 16px;
    }
  }
}
:deep(.ant-table-tbody) {
  height: 48px;
  tr {
    td {
      height: 59px;
    }
  }
}
.page_type {
  display: inline-block;
  background: rgba(24, 144, 255, 0.1);
  border-radius: 2px;
  border: 1px solid rgba(24, 144, 255, 0.1);
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #1890ff;
  line-height: 17px;
  text-align: center;
  font-style: normal;
  padding: 0 6px;
}
.status {
  display: flex;
  width: 62px;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  img {
    width: 12px;
    margin-right: 2px;
  }
}
.status_wks {
  border: 1px solid #2bb840;
  color: #2bb840;
}
.status_zbz {
  border: 1px solid #1890ff;
  color: #1890ff;
}
.status_yjs {
  border: 1px solid #eb5d44;
  color: #eb5d44;
}
.onSearchBox {
  width: 312px;
}
:deep(.onSearchBox .ant-btn-primary) {
  background: #f5f7fa;
  border: 1px solid #e5e6eb;
  color: #7e8081;
  box-shadow: unset;
}
</style>